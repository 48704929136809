namespace $ {
	export class $mol_icon_bookmark_outline extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M17,18L12,15.82L7,18V5H17M17,3H7C5.9,3 5,3.9 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z
		 * ```
		 */
		path() {
			return "M17,18L12,15.82L7,18V5H17M17,3H7C5.9,3 5,3.9 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z"
		}
	}
	
}

