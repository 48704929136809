namespace $ {
	export class $hyoo_meta_rights extends $mol_page {
		
		/**
		 * ```tree
		 * title @ \Rights
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_meta_rights_title' )
		}
		
		/**
		 * ```tree
		 * editors
		 * ```
		 */
		editors() {
			return this.meta().editors()
		}
		
		/**
		 * ```tree
		 * meta $hyoo_meta_model editors => editors
		 * ```
		 */
		@ $mol_mem
		meta() {
			const obj = new this.$.$hyoo_meta_model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * body / <= Content
		 * ```
		 */
		body() {
			return [
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * peer* $hyoo_meta_person
		 * ```
		 */
		@ $mol_mem_key
		peer(id: any) {
			const obj = new this.$.$hyoo_meta_person()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editor_link*0_0 $hyoo_meta_link meta <= peer*
		 * ```
		 */
		@ $mol_mem_key
		Editor_link(id: any) {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.peer(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_list / <= Editor_link*0_0
		 * ```
		 */
		editor_list() {
			return [
				this.Editor_link("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Editor_list $mol_list rows <= editor_list
		 * ```
		 */
		@ $mol_mem
		Editor_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.editor_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_add_bid @ \Irrevocable!
		 * ```
		 */
		editor_add_bid() {
			return this.$.$mol_locale.text( '$hyoo_meta_rights_editor_add_bid' )
		}
		
		/**
		 * ```tree
		 * editor_add_id? \
		 * ```
		 */
		@ $mol_mem
		editor_add_id(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * editable false
		 * ```
		 */
		editable() {
			return false
		}
		
		/**
		 * ```tree
		 * Editor_add_id $mol_string
		 * 	hint @ \Id or Profile Link
		 * 	value? <=> editor_add_id?
		 * 	enabled <= editable
		 * ```
		 */
		@ $mol_mem
		Editor_add_id() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_rights_Editor_add_id_hint' )
			obj.value = (next?: any) => this.editor_add_id(next)
			obj.enabled = () => this.editable()
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_add_allowed false
		 * ```
		 */
		editor_add_allowed() {
			return false
		}
		
		/**
		 * ```tree
		 * editor_add_submit? null
		 * ```
		 */
		@ $mol_mem
		editor_add_submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Editor_add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Editor_add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editor_add_submit $mol_button_major
		 * 	enabled <= editor_add_allowed
		 * 	click? <=> editor_add_submit?
		 * 	sub / <= Editor_add_icon
		 * ```
		 */
		@ $mol_mem
		Editor_add_submit() {
			const obj = new this.$.$mol_button_major()
			
			obj.enabled = () => this.editor_add_allowed()
			obj.click = (next?: any) => this.editor_add_submit(next)
			obj.sub = () => [
				this.Editor_add_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editor_add_bar $mol_bar sub /
		 * 	<= Editor_add_id
		 * 	<= Editor_add_submit
		 * ```
		 */
		@ $mol_mem
		Editor_add_bar() {
			const obj = new this.$.$mol_bar()
			
			obj.sub = () => [
				this.Editor_add_id(),
				this.Editor_add_submit()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_add_preview $hyoo_meta_model
		 * ```
		 */
		@ $mol_mem
		editor_add_preview() {
			const obj = new this.$.$hyoo_meta_model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editor_add_preview $hyoo_meta_link meta <= editor_add_preview
		 * ```
		 */
		@ $mol_mem
		Editor_add_preview() {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.editor_add_preview()
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_fill_all? null
		 * ```
		 */
		@ $mol_mem
		editor_fill_all(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Editor_fill_all $mol_button_minor
		 * 	title @ \Allow anyone
		 * 	click? <=> editor_fill_all?
		 * ```
		 */
		@ $mol_mem
		Editor_fill_all() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_meta_rights_Editor_fill_all_title' )
			obj.click = (next?: any) => this.editor_fill_all(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * editor_add_rows /
		 * 	<= Editor_add_bar
		 * 	<= Editor_add_preview
		 * 	<= Editor_fill_all
		 * ```
		 */
		editor_add_rows() {
			return [
				this.Editor_add_bar(),
				this.Editor_add_preview(),
				this.Editor_fill_all()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Editor_add_form $mol_list rows <= editor_add_rows
		 * ```
		 */
		@ $mol_mem
		Editor_add_form() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.editor_add_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editor_add $mol_form_field
		 * 	name @ \Add new
		 * 	bid <= editor_add_bid
		 * 	Content <= Editor_add_form
		 * ```
		 */
		@ $mol_mem
		Editor_add() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_meta_rights_Editor_add_name' )
			obj.bid = () => this.editor_add_bid()
			obj.Content = () => this.Editor_add_form()
			
			return obj
		}
		
		/**
		 * ```tree
		 * blocks /
		 * 	<= Editor_list
		 * 	<= Editor_add
		 * ```
		 */
		blocks() {
			return [
				this.Editor_list(),
				this.Editor_add()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Content $mol_list rows <= blocks
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.blocks()
			
			return obj
		}
	}
	
}

