namespace $ {
	export class $hyoo_page_menu extends $hyoo_meta_menu {
		
		/**
		 * ```tree
		 * title @ \In Bookmarks
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_page_menu_title' )
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	^
		 * 	mol_theme \$mol_theme_special
		 * ```
		 */
		attr() {
			return {
				...super.attr(),
				mol_theme: "$mol_theme_special"
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * head /
		 * 	<= Profile
		 * 	<= Title
		 * 	<= Tools
		 * ```
		 */
		head() {
			return [
				this.Profile(),
				this.Title(),
				this.Tools()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo null
		 * ```
		 */
		Logo() {
			return null as any
		}
		
		/**
		 * ```tree
		 * foot /
		 * 	<= Online
		 * 	<= Safe_showing
		 * 	<= About
		 * 	<= Aura_showing
		 * 	<= Lights
		 * ```
		 */
		foot() {
			return [
				this.Online(),
				this.Safe_showing(),
				this.About(),
				this.Aura_showing(),
				this.Lights()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Profile $hyoo_meta_link
		 * 	meta <= side
		 * 	hint @ \Profile
		 * 	Title null
		 * 	relation \nofollow
		 * ```
		 */
		@ $mol_mem
		Profile() {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.side()
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_menu_Profile_hint' )
			obj.Title = () => null as any
			obj.relation = () => "nofollow"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Online $hyoo_sync_online yard <= yard
		 * ```
		 */
		@ $mol_mem
		Online() {
			const obj = new this.$.$hyoo_sync_online()
			
			obj.yard = () => this.yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Safe_icon $mol_icon_key_variant
		 * ```
		 */
		@ $mol_mem
		Safe_icon() {
			const obj = new this.$.$mol_icon_key_variant()
			
			return obj
		}
		
		/**
		 * ```tree
		 * safe_showing? false
		 * ```
		 */
		@ $mol_mem
		safe_showing(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Safe_showing $mol_check_icon
		 * 	hint @ \Private Keys
		 * 	Icon <= Safe_icon
		 * 	checked? <=> safe_showing?
		 * ```
		 */
		@ $mol_mem
		Safe_showing() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_menu_Safe_showing_hint' )
			obj.Icon = () => this.Safe_icon()
			obj.checked = (next?: any) => this.safe_showing(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * About $mol_link_iconed
		 * 	hint @ \About $hyoo_page
		 * 	title \
		 * 	uri \http://page.hyoo.ru/
		 * ```
		 */
		@ $mol_mem
		About() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_menu_About_hint' )
			obj.title = () => ""
			obj.uri = () => "http://page.hyoo.ru/"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Aura_icon $mol_icon_image
		 * ```
		 */
		@ $mol_mem
		Aura_icon() {
			const obj = new this.$.$mol_icon_image()
			
			return obj
		}
		
		/**
		 * ```tree
		 * aura_showing? true
		 * ```
		 */
		@ $mol_mem
		aura_showing(next?: any) {
			if ( next !== undefined ) return next as never
			return true
		}
		
		/**
		 * ```tree
		 * Aura_showing $mol_check_icon
		 * 	hint @ \Backgrounds Showing
		 * 	Icon <= Aura_icon
		 * 	checked? <=> aura_showing?
		 * ```
		 */
		@ $mol_mem
		Aura_showing() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_menu_Aura_showing_hint' )
			obj.Icon = () => this.Aura_icon()
			obj.checked = (next?: any) => this.aura_showing(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
	}
	
}

