namespace $ {
	export class $mol_icon_download extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z
		 * ```
		 */
		path() {
			return "M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
		}
	}
	
}

