namespace $ {
	export class $hyoo_page_side_menu extends $hyoo_meta_menu {
		
		/**
		 * ```tree
		 * side_current $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		side_current() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title $hyoo_meta_link meta <= side
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title
		 * ```
		 */
		title() {
			return this.side().title()
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side title => title
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
	}
	
}

