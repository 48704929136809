namespace $ {
	export class $hyoo_sync_online extends $mol_select {
		
		/**
		 * ```tree
		 * minimal_width 40
		 * ```
		 */
		minimal_width() {
			return 40
		}
		
		/**
		 * ```tree
		 * minimal_height 40
		 * ```
		 */
		minimal_height() {
			return 40
		}
		
		/**
		 * ```tree
		 * dictionary
		 * ```
		 */
		dictionary() {
			return this.yard().master_list()
		}
		
		/**
		 * ```tree
		 * master_cursor?
		 * ```
		 */
		master_cursor(next?: any) {
			return this.yard().master_cursor(next)
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_yard
		 * 	master_list => dictionary
		 * 	master_cursor? => master_cursor?
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Filter null
		 * ```
		 */
		Filter() {
			return null as any
		}
		
		/**
		 * ```tree
		 * option_content* /
		 * 	<= Option_logo*
		 * 	<= option_label*
		 * ```
		 */
		option_content(id: any) {
			return [
				this.Option_logo(id),
				this.option_label(id)
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * trigger_content / <= Link
		 * ```
		 */
		trigger_content() {
			return [
				this.Link()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * master_id* \
		 * ```
		 */
		master_id(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Option_logo* $mol_avatar id <= master_id*
		 * ```
		 */
		@ $mol_mem_key
		Option_logo(id: any) {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.master_id(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * master_link \
		 * ```
		 */
		master_link() {
			return ""
		}
		
		/**
		 * ```tree
		 * Well $mol_avatar id <= master_link
		 * ```
		 */
		@ $mol_mem
		Well() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.master_link()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Fail $mol_icon_sync_off
		 * ```
		 */
		@ $mol_mem
		Fail() {
			const obj = new this.$.$mol_icon_sync_off()
			
			return obj
		}
		
		/**
		 * ```tree
		 * link_content /
		 * 	<= Well
		 * 	<= Fail
		 * ```
		 */
		link_content() {
			return [
				this.Well(),
				this.Fail()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * hint \$hyoo_sync
		 * ```
		 */
		hint() {
			return "$hyoo_sync"
		}
		
		/**
		 * ```tree
		 * message <= hint
		 * ```
		 */
		message() {
			return this.hint()
		}
		
		/**
		 * ```tree
		 * Link $mol_link
		 * 	uri <= master_link
		 * 	sub <= link_content
		 * 	hint <= message
		 * ```
		 */
		@ $mol_mem
		Link() {
			const obj = new this.$.$mol_link()
			
			obj.uri = () => this.master_link()
			obj.sub = () => this.link_content()
			obj.hint = () => this.message()
			
			return obj
		}
	}
	
}

