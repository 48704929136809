namespace $ {
	export class $hyoo_match_intro extends $mol_page {
		
		/**
		 * ```tree
		 * title \Знакомство
		 * ```
		 */
		title() {
			return "Знакомство"
		}
		
		/**
		 * ```tree
		 * foot / <= Next
		 * ```
		 */
		foot() {
			return [
				this.Next()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Content
		 * ```
		 */
		body() {
			return [
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Next $mol_link
		 * 	title \Поехали 💨
		 * 	arg * \
		 * 		\settings
		 * ```
		 */
		@ $mol_mem
		Next() {
			const obj = new this.$.$mol_link()
			
			obj.title = () => "Поехали 💨"
			obj.arg = () => ({
				"": "settings"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Content $mol_text text \
		 * 	\" 🚧 Без рекламы и вымогательства
		 * 	\" 🎭 Без фейков и мёртвых душ
		 * 	\" 📸 Фокус только на поиске пары
		 * 	\
		 * 	\! **Правила**
		 * 	\  ! \\Почему это работает?\https://page.hyoo.ru/#!=rkjlvv_lle0qa\\
		 * 	\
		 * 	\" 🤳 Заполни свой \\профиль\#!=settings\\
		 * 	\" 📅 Обновляй фото каждый день
		 * 	\" 💗 Лайкай других \\персон\#!=look\\
		 * 	\" 💬 Общайся при взаимности
		 * 	\" 🔄 Возвращайся через час
		 * 	\
		 * 	\**Это всё не ради денег, но ради любви**
		 * 	\
		 * 	\" \\Расскажи о $hyoo_match всем\https://match.hyoo.ru/\\
		 * 	\" \\Поддержи нас рублём\https://boosty.to/hyoo\\
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "\" 🚧 Без рекламы и вымогательства\n\" 🎭 Без фейков и мёртвых душ\n\" 📸 Фокус только на поиске пары\n\n! **Правила**\n  ! \\\\Почему это работает?\\https://page.hyoo.ru/#!=rkjlvv_lle0qa\\\\\n\n\" 🤳 Заполни свой \\\\профиль\\#!=settings\\\\\n\" 📅 Обновляй фото каждый день\n\" 💗 Лайкай других \\\\персон\\#!=look\\\\\n\" 💬 Общайся при взаимности\n\" 🔄 Возвращайся через час\n\n**Это всё не ради денег, но ради любви**\n\n\" \\\\Расскажи о $hyoo_match всем\\https://match.hyoo.ru/\\\\\n\" \\\\Поддержи нас рублём\\https://boosty.to/hyoo\\\\"
			
			return obj
		}
	}
	
}

