namespace $ {
	export class $hyoo_match_lobby_page extends $mol_page {
		
		/**
		 * ```tree
		 * title \Народ
		 * ```
		 */
		title() {
			return "Народ"
		}
		
		/**
		 * ```tree
		 * lobby $hyoo_match_lobby
		 * ```
		 */
		@ $mol_mem
		lobby() {
			const obj = new this.$.$hyoo_match_lobby()
			
			return obj
		}
		
		/**
		 * ```tree
		 * body / <= Single_list
		 * ```
		 */
		body() {
			return [
				this.Single_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * single* $hyoo_match_single
		 * ```
		 */
		@ $mol_mem_key
		single(id: any) {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Single*0_0 $hyoo_match_single_card single <= single*
		 * ```
		 */
		@ $mol_mem_key
		Single(id: any) {
			const obj = new this.$.$hyoo_match_single_card()
			
			obj.single = () => this.single(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * single_list / <= Single*0_0
		 * ```
		 */
		single_list() {
			return [
				this.Single("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Single_list $mol_list rows <= single_list
		 * ```
		 */
		@ $mol_mem
		Single_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.single_list()
			
			return obj
		}
	}
	
}

