namespace $ {
	export class $hyoo_meta_link extends $mol_link {
		
		/**
		 * ```tree
		 * minimal_height 40
		 * ```
		 */
		minimal_height() {
			return 40
		}
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.meta().id()
		}
		
		/**
		 * ```tree
		 * title
		 * ```
		 */
		title() {
			return this.meta().title()
		}
		
		/**
		 * ```tree
		 * meta $hyoo_meta_model
		 * 	id => id
		 * 	title => title
		 * ```
		 */
		@ $mol_mem
		meta() {
			const obj = new this.$.$hyoo_meta_model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * param \
		 * ```
		 */
		param() {
			return ""
		}
		
		/**
		 * ```tree
		 * all_title @ \All
		 * ```
		 */
		all_title() {
			return this.$.$mol_locale.text( '$hyoo_meta_link_all_title' )
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Avatar
		 * 	<= Title
		 * ```
		 */
		sub() {
			return [
				this.Avatar(),
				this.Title()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Avatar $mol_avatar id <= id
		 * ```
		 */
		@ $mol_mem
		Avatar() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.id()
			
			return obj
		}
		
		/**
		 * ```tree
		 * highlight \
		 * ```
		 */
		highlight() {
			return ""
		}
		
		/**
		 * ```tree
		 * Title $mol_dimmer
		 * 	haystack <= title
		 * 	needle <= highlight
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_dimmer()
			
			obj.haystack = () => this.title()
			obj.needle = () => this.highlight()
			
			return obj
		}
	}
	
}

