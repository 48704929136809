namespace $ {
	export class $hyoo_page extends $mol_book2 {
		
		/**
		 * ```tree
		 * side_main_id \iy8wtn_tky6pc
		 * ```
		 */
		side_main_id() {
			return "iy8wtn_tky6pc"
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_client
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_client()
			
			return obj
		}
		
		/**
		 * ```tree
		 * side_title*
		 * ```
		 */
		side_title(id: any) {
			return this.side(id).title()
		}
		
		/**
		 * ```tree
		 * pages_node*
		 * ```
		 */
		pages_node(id: any) {
			return this.side(id).pages_node()
		}
		
		/**
		 * ```tree
		 * side* $hyoo_page_side
		 * 	title => side_title*
		 * 	pages_node => pages_node*
		 * ```
		 */
		@ $mol_mem_key
		side(id: any) {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * side_current $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		side_current() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * bookmarks_node
		 * ```
		 */
		bookmarks_node() {
			return this.profile().bookmarks_node()
		}
		
		/**
		 * ```tree
		 * profile $hyoo_page_side bookmarks_node => bookmarks_node
		 * ```
		 */
		@ $mol_mem
		profile() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * style *
		 * 	^
		 * 	backgroundImage <= aura_image
		 * ```
		 */
		style() {
			return {
				...super.style(),
				backgroundImage: this.aura_image()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * auto / <= ref_track
		 * ```
		 */
		auto() {
			return [
				this.ref_track()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * pages /
		 * 	<= News
		 * 	<= Side_menu
		 * 	<= View*0_0
		 * 	<= Edit*0_0
		 * 	<= Info*0_0
		 * 	<= Rights*0_0
		 * 	<= Safe
		 * ```
		 */
		pages() {
			return [
				this.News(),
				this.Side_menu(),
				this.View("0_0"),
				this.Edit("0_0"),
				this.Info("0_0"),
				this.Rights("0_0"),
				this.Safe()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Placeholder <= Menu
		 * ```
		 */
		Placeholder() {
			return this.Menu()
		}
		
		/**
		 * ```tree
		 * aura_image \
		 * ```
		 */
		aura_image() {
			return ""
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * ref_track null
		 * ```
		 */
		ref_track() {
			return null as any
		}
		
		/**
		 * ```tree
		 * News $hyoo_page_side_news side <= profile
		 * ```
		 */
		@ $mol_mem
		News() {
			const obj = new this.$.$hyoo_page_side_news()
			
			obj.side = () => this.profile()
			
			return obj
		}
		
		/**
		 * ```tree
		 * book_side $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		book_side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * book_pages_node null
		 * ```
		 */
		book_pages_node() {
			return null as any
		}
		
		/**
		 * ```tree
		 * side_uri* \
		 * ```
		 */
		side_uri(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * side_add*? null
		 * ```
		 */
		@ $mol_mem_key
		side_add(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * tools_ext /
		 * ```
		 */
		tools_ext() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Side_menu $hyoo_page_side_menu
		 * 	yard <= yard
		 * 	side <= book_side
		 * 	side_current <= side_current
		 * 	list <= book_pages_node
		 * 	item_list* <= pages_node*
		 * 	item_uri* <= side_uri*
		 * 	item_add*? <=> side_add*?
		 * 	tools_ext <= tools_ext
		 * ```
		 */
		@ $mol_mem
		Side_menu() {
			const obj = new this.$.$hyoo_page_side_menu()
			
			obj.yard = () => this.yard()
			obj.side = () => this.book_side()
			obj.side_current = () => this.side_current()
			obj.list = () => this.book_pages_node()
			obj.item_list = (id: any) => this.pages_node(id)
			obj.item_uri = (id: any) => this.side_uri(id)
			obj.item_add = (id: any, next?: any) => this.side_add(id, next)
			obj.tools_ext = () => this.tools_ext()
			
			return obj
		}
		
		/**
		 * ```tree
		 * side_menu_showed? false
		 * ```
		 */
		@ $mol_mem
		side_menu_showed(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * editing? false
		 * ```
		 */
		@ $mol_mem
		editing(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * info? false
		 * ```
		 */
		@ $mol_mem
		info(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * View_details*
		 * ```
		 */
		View_details(id: any) {
			return this.View(id).Details()
		}
		
		/**
		 * ```tree
		 * View*0_0 $hyoo_page_side_view
		 * 	side <= side*
		 * 	peer* <= side*
		 * 	profile <= profile
		 * 	menu_showed? <=> side_menu_showed?
		 * 	editing? <=> editing?
		 * 	info? <=> info?
		 * 	highlight <= search
		 * 	Details => View_details*
		 * ```
		 */
		@ $mol_mem_key
		View(id: any) {
			const obj = new this.$.$hyoo_page_side_view()
			
			obj.side = () => this.side(id)
			obj.peer = (id: any) => this.side(id)
			obj.profile = () => this.profile()
			obj.menu_showed = (next?: any) => this.side_menu_showed(next)
			obj.editing = (next?: any) => this.editing(next)
			obj.info = (next?: any) => this.info(next)
			obj.highlight = () => this.search()
			
			return obj
		}
		
		/**
		 * ```tree
		 * rights? false
		 * ```
		 */
		@ $mol_mem
		rights(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * edit_close*? null
		 * ```
		 */
		@ $mol_mem_key
		edit_close(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Edit*0_0 $hyoo_page_side_edit
		 * 	side <= side*
		 * 	rights? <=> rights?
		 * 	close? <=> edit_close*?
		 * ```
		 */
		@ $mol_mem_key
		Edit(id: any) {
			const obj = new this.$.$hyoo_page_side_edit()
			
			obj.side = () => this.side(id)
			obj.rights = (next?: any) => this.rights(next)
			obj.close = (next?: any) => this.edit_close(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * info_close*? null
		 * ```
		 */
		@ $mol_mem_key
		info_close(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Info*0_0 $hyoo_page_side_info
		 * 	side <= side*
		 * 	close? <=> info_close*?
		 * 	Text <= View_details*
		 * ```
		 */
		@ $mol_mem_key
		Info(id: any) {
			const obj = new this.$.$hyoo_page_side_info()
			
			obj.side = () => this.side(id)
			obj.close = (next?: any) => this.info_close(id, next)
			obj.Text = () => this.View_details(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * rights_close? null
		 * ```
		 */
		@ $mol_mem
		rights_close(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Сlose_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Сlose_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Rights_close $mol_button_minor
		 * 	click? <=> rights_close?
		 * 	sub / <= Сlose_icon
		 * ```
		 */
		@ $mol_mem
		Rights_close() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.rights_close(next)
			obj.sub = () => [
				this.Сlose_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Rights*0_0 $hyoo_meta_rights
		 * 	meta <= side*
		 * 	peer* <= side*
		 * 	tools / <= Rights_close
		 * ```
		 */
		@ $mol_mem_key
		Rights(id: any) {
			const obj = new this.$.$hyoo_meta_rights()
			
			obj.meta = () => this.side(id)
			obj.peer = (id: any) => this.side(id)
			obj.tools = () => [
				this.Rights_close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * safe_close? null
		 * ```
		 */
		@ $mol_mem
		safe_close(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Safe_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Safe_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Safe_close $mol_button_minor
		 * 	click? <=> safe_close?
		 * 	sub / <= Safe_icon
		 * ```
		 */
		@ $mol_mem
		Safe_close() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.safe_close(next)
			obj.sub = () => [
				this.Safe_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Safe $hyoo_meta_safe
		 * 	yard <= yard
		 * 	tools / <= Safe_close
		 * ```
		 */
		@ $mol_mem
		Safe() {
			const obj = new this.$.$hyoo_meta_safe()
			
			obj.yard = () => this.yard()
			obj.tools = () => [
				this.Safe_close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * page_add? null
		 * ```
		 */
		@ $mol_mem
		page_add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * aura_showing? true
		 * ```
		 */
		@ $mol_mem
		aura_showing(next?: any) {
			if ( next !== undefined ) return next as never
			return true
		}
		
		/**
		 * ```tree
		 * safe? false
		 * ```
		 */
		@ $mol_mem
		safe(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * search
		 * ```
		 */
		search() {
			return this.Menu().search()
		}
		
		/**
		 * ```tree
		 * Menu $hyoo_page_menu
		 * 	yard <= yard
		 * 	side <= profile
		 * 	list <= bookmarks_node
		 * 	item_uri* <= side_uri*
		 * 	add? <=> page_add?
		 * 	item_add*? <=> side_add*?
		 * 	aura_showing? <=> aura_showing?
		 * 	safe_showing? <=> safe?
		 * 	search => search
		 * ```
		 */
		@ $mol_mem
		Menu() {
			const obj = new this.$.$hyoo_page_menu()
			
			obj.yard = () => this.yard()
			obj.side = () => this.profile()
			obj.list = () => this.bookmarks_node()
			obj.item_uri = (id: any) => this.side_uri(id)
			obj.add = (next?: any) => this.page_add(next)
			obj.item_add = (id: any, next?: any) => this.side_add(id, next)
			obj.aura_showing = (next?: any) => this.aura_showing(next)
			obj.safe_showing = (next?: any) => this.safe(next)
			
			return obj
		}
	}
	
}

