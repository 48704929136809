namespace $ {
	export class $hyoo_page_side_info extends $mol_page {
		
		/**
		 * ```tree
		 * text_tokens
		 * ```
		 */
		text_tokens() {
			return this.Text().flow_tokens()
		}
		
		/**
		 * ```tree
		 * text_header_title*
		 * ```
		 */
		text_header_title(id: any) {
			return this.Text().block_text(id)
		}
		
		/**
		 * ```tree
		 * section_arg*
		 * ```
		 */
		section_arg(id: any) {
			return this.Text().header_arg(id)
		}
		
		/**
		 * ```tree
		 * section_level*
		 * ```
		 */
		section_level(id: any) {
			return this.Text().header_level(id)
		}
		
		/**
		 * ```tree
		 * Text $mol_text
		 * 	flow_tokens => text_tokens
		 * 	block_text* => text_header_title*
		 * 	header_arg* => section_arg*
		 * 	header_level* => section_level*
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * details
		 * ```
		 */
		details() {
			return this.side().details()
		}
		
		/**
		 * ```tree
		 * details_node
		 * ```
		 */
		details_node() {
			return this.side().details_node()
		}
		
		/**
		 * ```tree
		 * referrers_list
		 * ```
		 */
		referrers_list() {
			return this.side().referrers_list()
		}
		
		/**
		 * ```tree
		 * referrers_stat*
		 * ```
		 */
		referrers_stat(id: any) {
			return this.side().referrers_stat(id)
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side
		 * 	details => details
		 * 	details_node => details_node
		 * 	referrers_list => referrers_list
		 * 	referrers_stat* => referrers_stat*
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Page Info
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_page_side_info_title' )
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Slides
		 * 	<= Close
		 * ```
		 */
		tools() {
			return [
				this.Slides(),
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Stat
		 * 	<= Section_list
		 * 	<= Ref_list
		 * 	<= Word_list
		 * ```
		 */
		body() {
			return [
				this.Stat(),
				this.Section_list(),
				this.Ref_list(),
				this.Word_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * slides_uri \https://slides.hyoo.ru/#!slides={source}
		 * ```
		 */
		slides_uri() {
			return "https://slides.hyoo.ru/#!slides={source}"
		}
		
		/**
		 * ```tree
		 * Slides $mol_link_iconed
		 * 	hint @ \View as Slides
		 * 	uri <= slides_uri
		 * 	title \
		 * ```
		 */
		@ $mol_mem
		Slides() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Slides_hint' )
			obj.uri = () => this.slides_uri()
			obj.title = () => ""
			
			return obj
		}
		
		/**
		 * ```tree
		 * close? null
		 * ```
		 */
		@ $mol_mem
		close(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_button_minor
		 * 	click? <=> close?
		 * 	sub / <= Close_icon
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.close(next)
			obj.sub = () => [
				this.Close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * size \0
		 * ```
		 */
		size() {
			return "0"
		}
		
		/**
		 * ```tree
		 * Size $mol_labeler
		 * 	title @ \Size
		 * 	content / <= size
		 * ```
		 */
		@ $mol_mem
		Size() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Size_title' )
			obj.content = () => [
				this.size()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * chars \0
		 * ```
		 */
		chars() {
			return "0"
		}
		
		/**
		 * ```tree
		 * Chars $mol_labeler
		 * 	title @ \Chars
		 * 	content / <= chars
		 * ```
		 */
		@ $mol_mem
		Chars() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Chars_title' )
			obj.content = () => [
				this.chars()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * words \0
		 * ```
		 */
		words() {
			return "0"
		}
		
		/**
		 * ```tree
		 * Words $mol_labeler
		 * 	title @ \Words
		 * 	content / <= words
		 * ```
		 */
		@ $mol_mem
		Words() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Words_title' )
			obj.content = () => [
				this.words()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * weight \0B
		 * ```
		 */
		weight() {
			return "0B"
		}
		
		/**
		 * ```tree
		 * Weight $mol_labeler
		 * 	title @ \Weight
		 * 	content / <= weight
		 * ```
		 */
		@ $mol_mem
		Weight() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Weight_title' )
			obj.content = () => [
				this.weight()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stat $mol_view sub /
		 * 	<= Size
		 * 	<= Chars
		 * 	<= Words
		 * 	<= Weight
		 * ```
		 */
		@ $mol_mem
		Stat() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Size(),
				this.Chars(),
				this.Words(),
				this.Weight()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * section_expanded? false
		 * ```
		 */
		@ $mol_mem
		section_expanded(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * section_title* \
		 * ```
		 */
		section_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Section_link*0 $mol_link
		 * 	arg <= section_arg*
		 * 	title <= section_title*
		 * ```
		 */
		@ $mol_mem_key
		Section_link(id: any) {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => this.section_arg(id)
			obj.title = () => this.section_title(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * section_list / <= Section_link*0
		 * ```
		 */
		section_list() {
			return [
				this.Section_link("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Section_list $mol_expander
		 * 	title @ \Sections
		 * 	expanded? <=> section_expanded?
		 * 	content <= section_list
		 * ```
		 */
		@ $mol_mem
		Section_list() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Section_list_title' )
			obj.expanded = (next?: any) => this.section_expanded(next)
			obj.content = () => this.section_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * ref_expanded? false
		 * ```
		 */
		@ $mol_mem
		ref_expanded(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * ref_uri* \
		 * ```
		 */
		ref_uri(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Ref_item_link* $mol_link_iconed uri <= ref_uri*
		 * ```
		 */
		@ $mol_mem_key
		Ref_item_link(id: any) {
			const obj = new this.$.$mol_link_iconed()
			
			obj.uri = () => this.ref_uri(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * ref_stat* 0
		 * ```
		 */
		ref_stat(id: any) {
			return 0
		}
		
		/**
		 * ```tree
		 * Ref_item_stat* $mol_view sub / <= ref_stat*
		 * ```
		 */
		@ $mol_mem_key
		Ref_item_stat(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.ref_stat(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Ref_item*0_0 $mol_view sub /
		 * 	<= Ref_item_link*
		 * 	<= Ref_item_stat*
		 * ```
		 */
		@ $mol_mem_key
		Ref_item(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Ref_item_link(id),
				this.Ref_item_stat(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * ref_list / <= Ref_item*0_0
		 * ```
		 */
		ref_list() {
			return [
				this.Ref_item("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Ref_list $mol_expander
		 * 	title @ \Referrers
		 * 	expanded? <=> ref_expanded?
		 * 	content <= ref_list
		 * ```
		 */
		@ $mol_mem
		Ref_list() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Ref_list_title' )
			obj.expanded = (next?: any) => this.ref_expanded(next)
			obj.content = () => this.ref_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * word_item_text* \
		 * ```
		 */
		word_item_text(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Word_item_text* $mol_view sub / <= word_item_text*
		 * ```
		 */
		@ $mol_mem_key
		Word_item_text(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.word_item_text(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * word_item_stat* 0
		 * ```
		 */
		word_item_stat(id: any) {
			return 0
		}
		
		/**
		 * ```tree
		 * Word_item_stat* $mol_speck
		 * 	value <= word_item_stat*
		 * 	theme \$mol_theme_special
		 * ```
		 */
		@ $mol_mem_key
		Word_item_stat(id: any) {
			const obj = new this.$.$mol_speck()
			
			obj.value = () => this.word_item_stat(id)
			obj.theme = () => "$mol_theme_special"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Word_item*0_0 $mol_view sub /
		 * 	<= Word_item_text*
		 * 	<= Word_item_stat*
		 * ```
		 */
		@ $mol_mem_key
		Word_item(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Word_item_text(id),
				this.Word_item_stat(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * word_list_items / <= Word_item*0_0
		 * ```
		 */
		word_list_items() {
			return [
				this.Word_item("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Word_list_items $mol_row sub <= word_list_items
		 * ```
		 */
		@ $mol_mem
		Word_list_items() {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => this.word_list_items()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Word_list $mol_expander
		 * 	title @ \Key Words
		 * 	content / <= Word_list_items
		 * ```
		 */
		@ $mol_mem
		Word_list() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_info_Word_list_title' )
			obj.content = () => [
				this.Word_list_items()
			] as readonly any[]
			
			return obj
		}
	}
	
}

