$hyoo_match_intro $mol_page
	title \Знакомимся
	foot /
		<= Next $mol_link
			title \Поехали 💨
			arg * \
				\settings
	body /
		<= Content $mol_text
			text \
				\" 🚧 Без рекламы и премиумов
				\" 🎭 Без фейков и мёртвых душ
				\" 📸 Только самые свежие фотки
				\" 🎲 Святой рандом и никакого AI
				\
				\! **Правила**
				\  ! \\Почему это работает?\https://page.hyoo.ru/#!=rkjlvv_lle0qa\\
				\
				\" 🤳 Заполни свой \\профиль\#!=settings\\
				\" 📅 Обновляй фото каждый день
				\" 💗 Лайкай других \\персон\#!=look\\
				\" 💬 Общайся при взаимности
				\" 🔄 Возвращайся через час
				\
				\**Это всё не ради денег, но ради любви**
				\
				\" Расскажи о \\match.hyoo.ru\https://match.hyoo.ru/\\ всем
				\" Поддержи \\рублём\https://boosty.to/hyoo\\ если не влом
