namespace $ {
	export class $mol_icon_publish extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M5,4V6H19V4H5M5,14H9V20H15V14H19L12,7L5,14Z
		 * ```
		 */
		path() {
			return "M5,4V6H19V4H5M5,14H9V20H15V14H19L12,7L5,14Z"
		}
	}
	
}

