namespace $ {
	export class $hyoo_match_single_page extends $mol_page {
		
		/**
		 * ```tree
		 * title \Персоны
		 * ```
		 */
		title() {
			return "Персоны"
		}
		
		/**
		 * ```tree
		 * self $hyoo_match_single
		 * ```
		 */
		@ $mol_mem
		self() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.pair().title()
		}
		
		/**
		 * ```tree
		 * greet
		 * ```
		 */
		greet() {
			return this.pair().greet()
		}
		
		/**
		 * ```tree
		 * contacts
		 * ```
		 */
		contacts() {
			return this.pair().contacts()
		}
		
		/**
		 * ```tree
		 * places
		 * ```
		 */
		places() {
			return this.pair().places()
		}
		
		/**
		 * ```tree
		 * photo_moment
		 * ```
		 */
		photo_moment() {
			return this.pair().photo_moment()
		}
		
		/**
		 * ```tree
		 * pair $hyoo_match_single
		 * 	title => name
		 * 	greet => greet
		 * 	contacts => contacts
		 * 	places => places
		 * 	photo_moment => photo_moment
		 * ```
		 */
		@ $mol_mem
		pair() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * mutual false
		 * ```
		 */
		mutual() {
			return false
		}
		
		/**
		 * ```tree
		 * title_content / <= name
		 * ```
		 */
		title_content() {
			return [
				this.name()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * tools / <= Moment
		 * ```
		 */
		tools() {
			return [
				this.Moment()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Gallery
		 * 	<= Match
		 * 	<= Places
		 * 	<= Brief
		 * ```
		 */
		body() {
			return [
				this.Gallery(),
				this.Match(),
				this.Places(),
				this.Brief()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Moment $mol_date value_moment <= photo_moment
		 * ```
		 */
		@ $mol_mem
		Moment() {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = () => this.photo_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * photo \
		 * ```
		 */
		photo() {
			return ""
		}
		
		/**
		 * ```tree
		 * Photo $mol_image uri <= photo
		 * ```
		 */
		@ $mol_mem
		Photo() {
			const obj = new this.$.$mol_image()
			
			obj.uri = () => this.photo()
			
			return obj
		}
		
		/**
		 * ```tree
		 * skip? null
		 * ```
		 */
		@ $mol_mem
		skip(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Skip_icon $mol_icon_window_close
		 * ```
		 */
		@ $mol_mem
		Skip_icon() {
			const obj = new this.$.$mol_icon_window_close()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Skip $mol_button_minor
		 * 	hint \Пропустить
		 * 	click? <=> skip?
		 * 	sub / <= Skip_icon
		 * ```
		 */
		@ $mol_mem
		Skip() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => "Пропустить"
			obj.click = (next?: any) => this.skip(next)
			obj.sub = () => [
				this.Skip_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Mutual_icon $mol_icon_heart_flash
		 * ```
		 */
		@ $mol_mem
		Mutual_icon() {
			const obj = new this.$.$mol_icon_heart_flash()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Mutual $mol_row sub / <= Mutual_icon
		 * ```
		 */
		@ $mol_mem
		Mutual() {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Mutual_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * like? null
		 * ```
		 */
		@ $mol_mem
		like(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Like_icon $mol_icon_heart_outline
		 * ```
		 */
		@ $mol_mem
		Like_icon() {
			const obj = new this.$.$mol_icon_heart_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Like $mol_button_minor
		 * 	hint \Нравится
		 * 	click? <=> like?
		 * 	sub / <= Like_icon
		 * ```
		 */
		@ $mol_mem
		Like() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => "Нравится"
			obj.click = (next?: any) => this.like(next)
			obj.sub = () => [
				this.Like_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gallery /
		 * 	<= Photo
		 * 	<= Skip
		 * 	<= Mutual
		 * 	<= Like
		 * ```
		 */
		gallery() {
			return [
				this.Photo(),
				this.Skip(),
				this.Mutual(),
				this.Like()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Gallery $mol_stack sub <= gallery
		 * ```
		 */
		@ $mol_mem
		Gallery() {
			const obj = new this.$.$mol_stack()
			
			obj.sub = () => this.gallery()
			
			return obj
		}
		
		/**
		 * ```tree
		 * match_hint \++Удача!++ --У вас есть {timeout} мин. для свидания.--
		 * ```
		 */
		match_hint() {
			return "++Удача!++ --У вас есть {timeout} мин. для свидания.--"
		}
		
		/**
		 * ```tree
		 * Match_hint $mol_text text <= match_hint
		 * ```
		 */
		@ $mol_mem
		Match_hint() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.match_hint()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts $mol_text text <= contacts
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.contacts()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Match $mol_list rows /
		 * 	<= Match_hint
		 * 	<= Contacts
		 * ```
		 */
		@ $mol_mem
		Match() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Match_hint(),
				this.Contacts()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Places $hyoo_match_places
		 * 	enabled false
		 * 	Pick null
		 * 	value? <= places
		 * ```
		 */
		@ $mol_mem
		Places() {
			const obj = new this.$.$hyoo_match_places()
			
			obj.enabled = () => false
			obj.Pick = () => null as any
			obj.value = (next?: any) => this.places()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief $mol_text text <= greet
		 * ```
		 */
		@ $mol_mem
		Brief() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.greet()
			
			return obj
		}
	}
	
}

