namespace $ {
	export class $hyoo_page_side_view extends $mol_page {
		
		/**
		 * ```tree
		 * profile $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		profile() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * peer* $hyoo_page_side
		 * ```
		 */
		@ $mol_mem_key
		peer(id: any) {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * book $hyoo_page_side
		 * ```
		 */
		@ $mol_mem
		book() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * highlight \
		 * ```
		 */
		highlight() {
			return ""
		}
		
		/**
		 * ```tree
		 * id
		 * ```
		 */
		id() {
			return this.side().id()
		}
		
		/**
		 * ```tree
		 * editable
		 * ```
		 */
		editable() {
			return this.side().editable()
		}
		
		/**
		 * ```tree
		 * title?
		 * ```
		 */
		title(next?: any) {
			return this.side().title(next)
		}
		
		/**
		 * ```tree
		 * side_details?
		 * ```
		 */
		side_details(next?: any) {
			return this.side().details(next)
		}
		
		/**
		 * ```tree
		 * released
		 * ```
		 */
		released() {
			return this.side().released()
		}
		
		/**
		 * ```tree
		 * side_release
		 * ```
		 */
		side_release() {
			return this.side().release()
		}
		
		/**
		 * ```tree
		 * changed_moment
		 * ```
		 */
		changed_moment() {
			return this.side().changed_moment()
		}
		
		/**
		 * ```tree
		 * authors
		 * ```
		 */
		authors() {
			return this.side().authors()
		}
		
		/**
		 * ```tree
		 * following
		 * ```
		 */
		following() {
			return this.side().following()
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side
		 * 	id => id
		 * 	editable => editable
		 * 	title? => title?
		 * 	details? => side_details?
		 * 	released => released
		 * 	release => side_release
		 * 	changed_moment => changed_moment
		 * 	authors => authors
		 * 	following => following
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * plugins / <= Search_start
		 * ```
		 */
		plugins() {
			return [
				this.Search_start()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * auto /
		 * 	<= slides_send
		 * 	<= history_mark
		 * ```
		 */
		auto() {
			return [
				this.slides_send(),
				this.history_mark()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo <= Menu_toggle
		 * ```
		 */
		Logo() {
			return this.Menu_toggle()
		}
		
		/**
		 * ```tree
		 * head /
		 * 	<= Title
		 * 	<= Tools
		 * 	<= Search
		 * ```
		 */
		head() {
			return [
				this.Title(),
				this.Tools(),
				this.Search()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Edit_toggle
		 * 	<= Search_toggle
		 * 	<= Info_toggle
		 * 	<= Bookmark_toggle
		 * ```
		 */
		tools() {
			return [
				this.Edit_toggle(),
				this.Search_toggle(),
				this.Info_toggle(),
				this.Bookmark_toggle()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * slides_content \
		 * 	\# {title}
		 * 	\{details}
		 * ```
		 */
		slides_content() {
			return "# {title}\n{details}"
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Details
		 * 	<= Signature
		 * ```
		 */
		body() {
			return [
				this.Details(),
				this.Signature()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * search_start? null
		 * ```
		 */
		@ $mol_mem
		search_start(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Search_start $mol_hotkey
		 * 	key * F? <=> search_start?
		 * 	mod_ctrl true
		 * ```
		 */
		@ $mol_mem
		Search_start() {
			const obj = new this.$.$mol_hotkey()
			
			obj.key = () => ({
				F: (next?: any) => this.search_start(next)
			} as Record< string, any >)
			obj.mod_ctrl = () => true
			
			return obj
		}
		
		/**
		 * ```tree
		 * slides_send null
		 * ```
		 */
		slides_send() {
			return null as any
		}
		
		/**
		 * ```tree
		 * history_mark null
		 * ```
		 */
		history_mark() {
			return null as any
		}
		
		/**
		 * ```tree
		 * menu_showed? false
		 * ```
		 */
		@ $mol_mem
		menu_showed(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Avatar $mol_avatar id <= id
		 * ```
		 */
		@ $mol_mem
		Avatar() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.id()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Menu_toggle $mol_check
		 * 	checked? <=> menu_showed?
		 * 	hint @ \Menu Showing
		 * 	sub / <= Avatar
		 * ```
		 */
		@ $mol_mem
		Menu_toggle() {
			const obj = new this.$.$mol_check()
			
			obj.checked = (next?: any) => this.menu_showed(next)
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_view_Menu_toggle_hint' )
			obj.sub = () => [
				this.Avatar()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * search_query?val \
		 * ```
		 */
		@ $mol_mem
		search_query(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * search_stop? null
		 * ```
		 */
		@ $mol_mem
		search_stop(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Search $mol_search_jumper
		 * 	query?val <=> search_query?val
		 * 	Root <= Details
		 * 	clear? <=> search_stop?
		 * ```
		 */
		@ $mol_mem
		Search() {
			const obj = new this.$.$mol_search_jumper()
			
			obj.query = (val?: any) => this.search_query(val)
			obj.Root = () => this.Details()
			obj.clear = (next?: any) => this.search_stop(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * editing? false
		 * ```
		 */
		@ $mol_mem
		editing(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Edit_icon $mol_icon_pencil_outline
		 * ```
		 */
		@ $mol_mem
		Edit_icon() {
			const obj = new this.$.$mol_icon_pencil_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Unreleased $mol_speck
		 * ```
		 */
		@ $mol_mem
		Unreleased() {
			const obj = new this.$.$mol_speck()
			
			return obj
		}
		
		/**
		 * ```tree
		 * edit_toggle_label / <= Unreleased
		 * ```
		 */
		edit_toggle_label() {
			return [
				this.Unreleased()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Edit_toggle $mol_check_icon
		 * 	hint @ \Edit page
		 * 	checked? <=> editing?
		 * 	Icon <= Edit_icon
		 * 	label <= edit_toggle_label
		 * ```
		 */
		@ $mol_mem
		Edit_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_view_Edit_toggle_hint' )
			obj.checked = (next?: any) => this.editing(next)
			obj.Icon = () => this.Edit_icon()
			obj.label = () => this.edit_toggle_label()
			
			return obj
		}
		
		/**
		 * ```tree
		 * search_show? false
		 * ```
		 */
		@ $mol_mem
		search_show(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Search_icon $mol_icon_magnify
		 * ```
		 */
		@ $mol_mem
		Search_icon() {
			const obj = new this.$.$mol_icon_magnify()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Search_toggle $mol_check_icon
		 * 	hint @ \Find on page
		 * 	checked? <=> search_show?
		 * 	Icon <= Search_icon
		 * ```
		 */
		@ $mol_mem
		Search_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_view_Search_toggle_hint' )
			obj.checked = (next?: any) => this.search_show(next)
			obj.Icon = () => this.Search_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * info? false
		 * ```
		 */
		@ $mol_mem
		info(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Info_icon $mol_icon_information_outline
		 * ```
		 */
		@ $mol_mem
		Info_icon() {
			const obj = new this.$.$mol_icon_information_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Info_toggle $mol_check_icon
		 * 	hint @ \Info page
		 * 	checked? <=> info?
		 * 	Icon <= Info_icon
		 * ```
		 */
		@ $mol_mem
		Info_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_view_Info_toggle_hint' )
			obj.checked = (next?: any) => this.info(next)
			obj.Icon = () => this.Info_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * bookmark? false
		 * ```
		 */
		@ $mol_mem
		bookmark(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Bookmark_icon $mol_icon_bookmark_outline
		 * ```
		 */
		@ $mol_mem
		Bookmark_icon() {
			const obj = new this.$.$mol_icon_bookmark_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Bookmark_toggle $mol_check_icon
		 * 	hint @ \Bookmark page
		 * 	checked? <=> bookmark?
		 * 	Icon <= Bookmark_icon
		 * ```
		 */
		@ $mol_mem
		Bookmark_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_view_Bookmark_toggle_hint' )
			obj.checked = (next?: any) => this.bookmark(next)
			obj.Icon = () => this.Bookmark_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * details \
		 * ```
		 */
		details() {
			return ""
		}
		
		/**
		 * ```tree
		 * Details $mol_text
		 * 	text <= details
		 * 	highlight <= search_query
		 * ```
		 */
		@ $mol_mem
		Details() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.details()
			obj.highlight = () => this.search_query()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Changed $mol_date value_moment <= changed_moment
		 * ```
		 */
		@ $mol_mem
		Changed() {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = () => this.changed_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * author_link* \https://page.hyoo.ru/#!={person}
		 * ```
		 */
		author_link(id: any) {
			return "https://page.hyoo.ru/#!={person}"
		}
		
		/**
		 * ```tree
		 * Author_link*0_0 $hyoo_meta_link
		 * 	meta <= peer*
		 * 	uri <= author_link*
		 * ```
		 */
		@ $mol_mem_key
		Author_link(id: any) {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.peer(id)
			obj.uri = () => this.author_link(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * author_list / <= Author_link*0_0
		 * ```
		 */
		author_list() {
			return [
				this.Author_link("0_0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Author_list $mol_view sub <= author_list
		 * ```
		 */
		@ $mol_mem
		Author_list() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.author_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Following $hyoo_meta_link meta <= following
		 * ```
		 */
		@ $mol_mem
		Following() {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.following()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Signature $mol_view sub /
		 * 	<= Changed
		 * 	<= Author_list
		 * 	<= Following
		 * ```
		 */
		@ $mol_mem
		Signature() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Changed(),
				this.Author_list(),
				this.Following()
			] as readonly any[]
			
			return obj
		}
	}
	
}

