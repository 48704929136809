namespace $ {
	export class $hyoo_meta_safe extends $mol_page {
		
		/**
		 * ```tree
		 * title @ \Private keys
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_meta_safe_title' )
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_yard
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * bid_pass_long @ \> 7 letters
		 * ```
		 */
		bid_pass_long() {
			return this.$.$mol_locale.text( '$hyoo_meta_safe_bid_pass_long' )
		}
		
		/**
		 * ```tree
		 * key_size 144
		 * ```
		 */
		key_size() {
			return 144
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	^
		 * 	mol_theme \$mol_theme_special
		 * ```
		 */
		attr() {
			return {
				...super.attr(),
				mol_theme: "$mol_theme_special"
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * body / <= Content
		 * ```
		 */
		body() {
			return [
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Expot_bid $mol_text text @ \Your private access keys are stored in the browser. In order not to lose them or transfer to another device, it is recommended to come up with a strong password for exporting them.
		 * ```
		 */
		@ $mol_mem
		Expot_bid() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.$.$mol_locale.text( '$hyoo_meta_safe_Expot_bid_text' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * password_bid \
		 * ```
		 */
		password_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * password? \
		 * ```
		 */
		@ $mol_mem
		password(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Password $mol_password value? <=> password?
		 * ```
		 */
		@ $mol_mem
		Password() {
			const obj = new this.$.$mol_password()
			
			obj.value = (next?: any) => this.password(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Password_field $mol_form_field
		 * 	name @ \Password
		 * 	bids / <= password_bid
		 * 	Content <= Password
		 * ```
		 */
		@ $mol_mem
		Password_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_meta_safe_Password_field_name' )
			obj.bids = () => [
				this.password_bid()
			] as readonly any[]
			obj.Content = () => this.Password()
			
			return obj
		}
		
		/**
		 * ```tree
		 * recall_enabled true
		 * ```
		 */
		recall_enabled() {
			return true
		}
		
		/**
		 * ```tree
		 * recall? \
		 * ```
		 */
		@ $mol_mem
		recall(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Recall $mol_string
		 * 	enabled <= recall_enabled
		 * 	value? <=> recall?
		 * ```
		 */
		@ $mol_mem
		Recall() {
			const obj = new this.$.$mol_string()
			
			obj.enabled = () => this.recall_enabled()
			obj.value = (next?: any) => this.recall(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Recall_field $mol_form_field
		 * 	name @ \Hint for recall
		 * 	Content <= Recall
		 * ```
		 */
		@ $mol_mem
		Recall_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_meta_safe_Recall_field_name' )
			obj.Content = () => this.Recall()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Export_pass $mol_list rows /
		 * 	<= Password_field
		 * 	<= Recall_field
		 * ```
		 */
		@ $mol_mem
		Export_pass() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Password_field(),
				this.Recall_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * export_link \
		 * ```
		 */
		export_link() {
			return ""
		}
		
		/**
		 * ```tree
		 * Export_link $mol_link
		 * 	uri <= export_link
		 * 	title @ \This link contains your encrypted prvate keys. Save it to a secure place on another physical device. Using this link you can restore your private keys by enterig same password.
		 * ```
		 */
		@ $mol_mem
		Export_link() {
			const obj = new this.$.$mol_link()
			
			obj.uri = () => this.export_link()
			obj.title = () => this.$.$mol_locale.text( '$hyoo_meta_safe_Export_link_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * export_rows /
		 * 	<= Expot_bid
		 * 	<= Export_pass
		 * 	<= Export_link
		 * ```
		 */
		export_rows() {
			return [
				this.Expot_bid(),
				this.Export_pass(),
				this.Export_link()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Export_block $mol_list rows <= export_rows
		 * ```
		 */
		@ $mol_mem
		Export_block() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.export_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Iport_descr $mol_text text @ \
		 * 	\To import a private key, you must enter the password from it.
		 * 	\**If the current key is not saved, it will be lost forever.**
		 * ```
		 */
		@ $mol_mem
		Iport_descr() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.$.$mol_locale.text( '$hyoo_meta_safe_Iport_descr_text' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * Import_pass $mol_list rows /
		 * 	<= Recall_field
		 * 	<= Password_field
		 * ```
		 */
		@ $mol_mem
		Import_pass() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Recall_field(),
				this.Password_field()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * import_switch? null
		 * ```
		 */
		@ $mol_mem
		import_switch(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * peer_new \
		 * ```
		 */
		peer_new() {
			return ""
		}
		
		/**
		 * ```tree
		 * Peer_new $mol_avatar id <= peer_new
		 * ```
		 */
		@ $mol_mem
		Peer_new() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.peer_new()
			
			return obj
		}
		
		/**
		 * ```tree
		 * impot_switch_title @ \Switch
		 * ```
		 */
		impot_switch_title() {
			return this.$.$mol_locale.text( '$hyoo_meta_safe_impot_switch_title' )
		}
		
		/**
		 * ```tree
		 * Import_switch $mol_button_minor
		 * 	click? <=> import_switch?
		 * 	sub /
		 * 		<= Peer_new
		 * 		<= impot_switch_title
		 * ```
		 */
		@ $mol_mem
		Import_switch() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.import_switch(next)
			obj.sub = () => [
				this.Peer_new(),
				this.impot_switch_title()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * import_rows /
		 * 	<= Iport_descr
		 * 	<= Import_pass
		 * 	<= Import_switch
		 * ```
		 */
		import_rows() {
			return [
				this.Iport_descr(),
				this.Import_pass(),
				this.Import_switch()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Import_block $mol_list rows <= import_rows
		 * ```
		 */
		@ $mol_mem
		Import_block() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.import_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * content /
		 * 	<= Export_block
		 * 	<= Import_block
		 * ```
		 */
		content() {
			return [
				this.Export_block(),
				this.Import_block()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Content $mol_list rows <= content
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.content()
			
			return obj
		}
	}
	
}

