namespace $ {
	export class $mol_icon_plus extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z
		 * ```
		 */
		path() {
			return "M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
		}
	}
	
}

