$hyoo_match_single_settings $mol_page
	title \Профиль
	single $hyoo_match_single
		ready => ready
		photo_fresh => photo_fresh
		title? => name?
		greet? => greet?
		contacts? => contacts?
		places? => places?
		univer? => univer?
		sex_self? => sex_self?
		age_self? => age_self?
		sex_pref*? => sex_pref*?
		sex_pref_all => sex_pref_all
		age_pref*? => age_pref*?
		age_pref_all => age_pref_all
	bid_required* \Обязательно
	bid_one* \Хотябы один
	tools /
		<= Invisible $mol_paragraph title \🙈 Не виден
		<= Visible $mol_paragraph title \👀 Виден всем
	foot /
		<= Incoplete $mol_paragraph title \❌ Профиль недозаполнен
		<= Next $mol_link
			title \✅ Смотреть других
			arg * \
				\look
	body /
		<= Shot_field $mol_form_field
			name \Сегодняшнее фото
			bids /
				<= shot_bid \Нужно свежее
			Content <= Shot $mol_check
				checked? <=> live? true
				sub <= shot_content /
					<= Photo $mol_image
						uri <= photo \
					<= Camera $mol_video_camera
						dom_node_actual => video
						aspect 1
					<= Shot_hint $mol_paragraph title \Жми чтобы сфотаться
		<= Name_field $mol_form_field
			name \Имя
			bids /
				<= bid_required*name
			Content <= Name $mol_string
				value? <=> name?
		<= Tags $mol_form_group sub /
			<= Self $mol_form_field
				name \Я из этих ...
				bids /
					<= bid_required*age_self
					<= bid_required*sex_self
				Content <= Self_controls $mol_view sub /
					<= Age_self $mol_switch
						value? <=> age_self?
						options *
							young \Молодых
							adult \Взрослых
							mature \Зрелых
					<= Sex_self $mol_switch
						value? <=> sex_self?
						options *
							female \Женщин
							male \Мужчин
			<= Pref $mol_form_field
				name @ \Мне нравятся ...
				bids /
					<= bid_one*age_pref_all
					<= bid_one*sex_pref_all
				Content <= Pref_controls $mol_view sub /
					<= Age_pref $mol_check_list
						option_checked*? <=> age_pref*?
						options *
							young \Молодые
							adult \Взрослые
							mature \Зрелые
					<= Sex_pref $mol_check_list
						option_checked*? <=> sex_pref*?
						options *
							female \Женщины
							male \Мужчины
		<= Location $mol_form_group sub /
			<= Univer_field $mol_form_field
				name \Универ
				bids /
				Content <= Univer $hyoo_match_univer
					value? <=> univer?
			<= Places_field $mol_form_field
				name \Регионы
				bids /
					<= bid_one*places
				Content <= Places $hyoo_match_places
					value? <=> places?
					pick_enabled <= places_enabled true
		- <= Syntax $mol_link_iconed
			hint \MarkedText
			uri \https://marked.hyoo.ru/
			title @ \Syntax
		<= Greet_field $mol_form_field
			name \Приветствие
			bids /
				<= bid_required*greet
			Content <= Greet $mol_textarea
				hint \заинтересуйте вашу пару
				value? <=> greet?
		<= Contacts_hint $mol_paragraph title \Для взаимных
		<= Contacts_field $mol_form_field
			name \Контакты
			bids /
				<= bid_required*contacts
			Content <= Contacts $mol_textarea
				hint \как с вами связаться
				value? <=> contacts?
