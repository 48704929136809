namespace $ {
	export class $hyoo_match_final extends $mol_page {
		
		/**
		 * ```tree
		 * title \Ждём персон
		 * ```
		 */
		title() {
			return "Ждём персон"
		}
		
		/**
		 * ```tree
		 * self $hyoo_match_single
		 * ```
		 */
		@ $mol_mem
		self() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * foot / <= Reset
		 * ```
		 */
		foot() {
			return [
				this.Reset()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Result
		 * 	<= Share
		 * 	<= Content
		 * ```
		 */
		body() {
			return [
				this.Result(),
				this.Share(),
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * reset? null
		 * ```
		 */
		@ $mol_mem
		reset(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Reset $mol_button_minor
		 * 	title \Заново 💦
		 * 	click? <=> reset?
		 * ```
		 */
		@ $mol_mem
		Reset() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => "Заново 💦"
			obj.click = (next?: any) => this.reset(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Result $mol_text text \Никого пока нет под твои критерии 😭
		 * ```
		 */
		@ $mol_mem
		Result() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "Никого пока нет под твои критерии 😭"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Share $mol_button_share
		 * 	capture <= Content
		 * 	uri \/
		 * 	title \Расскажи остальным:
		 * ```
		 */
		@ $mol_mem
		Share() {
			const obj = new this.$.$mol_button_share()
			
			obj.capture = () => this.Content()
			obj.uri = () => "/"
			obj.title = () => "Расскажи остальным:"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Content $mol_text text \
		 * 	\" 🔥 Tinder ушёл... встречай **match.hyoo.ru** - сервис знакомств, которому выгодно, чтобы ты нашёл себе пару и свалил в закат.
		 * 	\" Никаких фейков, мёртвых душ, рекламы и вымогательства денег. Поделись с друзьями - пусть тоже найдут свою половинку 💞
		 * 	\" Проект не коммерческий, так что вся надежда на тебя!
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "\" 🔥 Tinder ушёл... встречай **match.hyoo.ru** - сервис знакомств, которому выгодно, чтобы ты нашёл себе пару и свалил в закат.\n\" Никаких фейков, мёртвых душ, рекламы и вымогательства денег. Поделись с друзьями - пусть тоже найдут свою половинку 💞\n\" Проект не коммерческий, так что вся надежда на тебя!"
			
			return obj
		}
	}
	
}

