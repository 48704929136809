namespace $ {
	export class $hyoo_meta_menu_items extends $mol_list {
		
		/**
		 * ```tree
		 * id \
		 * ```
		 */
		id() {
			return ""
		}
		
		/**
		 * ```tree
		 * editing false
		 * ```
		 */
		editing() {
			return false
		}
		
		/**
		 * ```tree
		 * drop_allow /
		 * 	\move
		 * 	\link
		 * ```
		 */
		drop_allow() {
			return [
				"move",
				"link"
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * ids
		 * ```
		 */
		ids() {
			return this.list().list()
		}
		
		/**
		 * ```tree
		 * list $hyoo_crowd_list list => ids
		 * ```
		 */
		@ $mol_mem
		list() {
			const obj = new this.$.$hyoo_crowd_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_title*
		 * ```
		 */
		item_title(id: any) {
			return this.item(id).title()
		}
		
		/**
		 * ```tree
		 * item* $hyoo_meta_model title => item_title*
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_meta_model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_list* $hyoo_crowd_list
		 * ```
		 */
		@ $mol_mem_key
		item_list(id: any) {
			const obj = new this.$.$hyoo_crowd_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_moved*? null
		 * ```
		 */
		@ $mol_mem_key
		item_moved(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * rows <= items
		 * ```
		 */
		rows() {
			return this.items()
		}
		
		/**
		 * ```tree
		 * item_expandable* false
		 * ```
		 */
		item_expandable(id: any) {
			return false
		}
		
		/**
		 * ```tree
		 * item_expanded*? true
		 * ```
		 */
		@ $mol_mem_key
		item_expanded(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return true
		}
		
		/**
		 * ```tree
		 * Item_expand* $mol_check_expand
		 * 	expandable <= item_expandable*
		 * 	expanded? <=> item_expanded*?
		 * ```
		 */
		@ $mol_mem_key
		Item_expand(id: any) {
			const obj = new this.$.$mol_check_expand()
			
			obj.expandable = () => this.item_expandable(id)
			obj.expanded = (next?: any) => this.item_expanded(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * editable false
		 * ```
		 */
		editable() {
			return false
		}
		
		/**
		 * ```tree
		 * transfer_adopt? null
		 * ```
		 */
		@ $mol_mem
		transfer_adopt(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * receive_after*? null
		 * ```
		 */
		@ $mol_mem_key
		receive_after(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * item_drag_end*? null
		 * ```
		 */
		@ $mol_mem_key
		item_drag_end(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * item_text* \
		 * ```
		 */
		item_text(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * item_html* \
		 * ```
		 */
		item_html(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * item_uri* \
		 * ```
		 */
		item_uri(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * param \
		 * ```
		 */
		param() {
			return ""
		}
		
		/**
		 * ```tree
		 * highlight \
		 * ```
		 */
		highlight() {
			return ""
		}
		
		/**
		 * ```tree
		 * Item_link* $hyoo_meta_link
		 * 	meta <= item*
		 * 	param <= param
		 * 	highlight <= highlight
		 * 	Avatar null
		 * ```
		 */
		@ $mol_mem_key
		Item_link(id: any) {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.item(id)
			obj.param = () => this.param()
			obj.highlight = () => this.highlight()
			obj.Avatar = () => null as any
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_drag* $mol_drag
		 * 	end? <=> item_drag_end*?
		 * 	transfer *
		 * 		text/plain <= item_text*
		 * 		text/html <= item_html*
		 * 		text/uri-list <= item_uri*
		 * 	Sub <= Item_link*
		 * ```
		 */
		@ $mol_mem_key
		Item_drag(id: any) {
			const obj = new this.$.$mol_drag()
			
			obj.end = (next?: any) => this.item_drag_end(id, next)
			obj.transfer = () => ({
				"text/plain": this.item_text(id),
				"text/html": this.item_html(id),
				"text/uri-list": this.item_uri(id)
			} as Record< string, any >)
			obj.Sub = () => this.Item_link(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_drop_after* $mol_drop
		 * 	enabled <= editable
		 * 	adopt? <=> transfer_adopt?
		 * 	receive? <=> receive_after*?
		 * 	allow <= drop_allow
		 * 	Sub <= Item_drag*
		 * ```
		 */
		@ $mol_mem_key
		Item_drop_after(id: any) {
			const obj = new this.$.$mol_drop()
			
			obj.enabled = () => this.editable()
			obj.adopt = (next?: any) => this.transfer_adopt(next)
			obj.receive = (next?: any) => this.receive_after(id, next)
			obj.allow = () => this.drop_allow()
			obj.Sub = () => this.Item_drag(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_remove*? null
		 * ```
		 */
		@ $mol_mem_key
		item_remove(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Item_remove_icon* $mol_icon_cross
		 * ```
		 */
		@ $mol_mem_key
		Item_remove_icon(id: any) {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_remove* $mol_button_minor
		 * 	hint @ \Remove
		 * 	click? <=> item_remove*?
		 * 	sub / <= Item_remove_icon*
		 * ```
		 */
		@ $mol_mem_key
		Item_remove(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_items_Item_remove_hint' )
			obj.click = (next?: any) => this.item_remove(id, next)
			obj.sub = () => [
				this.Item_remove_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_pin*? null
		 * ```
		 */
		@ $mol_mem_key
		item_pin(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Item_pin_icon* $mol_icon_pin_outline
		 * ```
		 */
		@ $mol_mem_key
		Item_pin_icon(id: any) {
			const obj = new this.$.$mol_icon_pin_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_pin* $mol_button_minor
		 * 	hint @ \Pin here
		 * 	click? <=> item_pin*?
		 * 	sub / <= Item_pin_icon*
		 * ```
		 */
		@ $mol_mem_key
		Item_pin(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_items_Item_pin_hint' )
			obj.click = (next?: any) => this.item_pin(id, next)
			obj.sub = () => [
				this.Item_pin_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * receive_inside*? null
		 * ```
		 */
		@ $mol_mem_key
		receive_inside(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * item_add*? null
		 * ```
		 */
		@ $mol_mem_key
		item_add(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Item_add_icon* $mol_icon_plus
		 * ```
		 */
		@ $mol_mem_key
		Item_add_icon(id: any) {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_add* $mol_button_minor
		 * 	hint @ \Add page inside
		 * 	click? <=> item_add*?
		 * 	sub / <= Item_add_icon*
		 * ```
		 */
		@ $mol_mem_key
		Item_add(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_items_Item_add_hint' )
			obj.click = (next?: any) => this.item_add(id, next)
			obj.sub = () => [
				this.Item_add_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_drop_inside* $mol_drop
		 * 	enabled <= editable
		 * 	adopt? <=> transfer_adopt?
		 * 	receive? <=> receive_inside*?
		 * 	allow <= drop_allow
		 * 	Sub <= Item_add*
		 * ```
		 */
		@ $mol_mem_key
		Item_drop_inside(id: any) {
			const obj = new this.$.$mol_drop()
			
			obj.enabled = () => this.editable()
			obj.adopt = (next?: any) => this.transfer_adopt(next)
			obj.receive = (next?: any) => this.receive_inside(id, next)
			obj.allow = () => this.drop_allow()
			obj.Sub = () => this.Item_add(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_row* /
		 * 	<= Item_expand*
		 * 	<= Item_drop_after*
		 * 	<= Item_remove*
		 * 	<= Item_pin*
		 * 	<= Item_drop_inside*
		 * ```
		 */
		item_row(id: any) {
			return [
				this.Item_expand(id),
				this.Item_drop_after(id),
				this.Item_remove(id),
				this.Item_pin(id),
				this.Item_drop_inside(id)
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Item_row* $mol_view sub <= item_row*
		 * ```
		 */
		@ $mol_mem_key
		Item_row(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.item_row(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Item_items* $hyoo_meta_menu_items
		 * 	editing <= editing
		 * 	list <= item_list*
		 * 	item* <= item*
		 * 	item_moved*? <=> item_moved*?
		 * 	item_expanded*? <=> item_expanded*?
		 * 	item_list* <= item_list*
		 * 	item_uri* <= item_uri*
		 * 	highlight <= highlight
		 * 	item_add*? <=> item_add*?
		 * ```
		 */
		@ $mol_mem_key
		Item_items(id: any) {
			const obj = new this.$.$hyoo_meta_menu_items()
			
			obj.editing = () => this.editing()
			obj.list = () => this.item_list(id)
			obj.item = (id: any) => this.item(id)
			obj.item_moved = (id: any, next?: any) => this.item_moved(id, next)
			obj.item_expanded = (id: any, next?: any) => this.item_expanded(id, next)
			obj.item_list = (id: any) => this.item_list(id)
			obj.item_uri = (id: any) => this.item_uri(id)
			obj.highlight = () => this.highlight()
			obj.item_add = (id: any, next?: any) => this.item_add(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_content* /
		 * 	<= Item_row*
		 * 	<= Item_items*
		 * ```
		 */
		item_content(id: any) {
			return [
				this.Item_row(id),
				this.Item_items(id)
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Item*0_0 $mol_list rows <= item_content*
		 * ```
		 */
		@ $mol_mem_key
		Item(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.item_content(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * items / <= Item*0_0
		 * ```
		 */
		items() {
			return [
				this.Item("0_0")
			] as readonly any[]
		}
	}
	
}

