namespace $ {
	export class $hyoo_match_app extends $mol_book2_catalog {
		
		/**
		 * ```tree
		 * lobby $hyoo_match_lobby
		 * ```
		 */
		@ $mol_mem
		lobby() {
			const obj = new this.$.$hyoo_match_lobby()
			
			return obj
		}
		
		/**
		 * ```tree
		 * self $hyoo_match_single
		 * ```
		 */
		@ $mol_mem
		self() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * pair $hyoo_match_single
		 * ```
		 */
		@ $mol_mem
		pair() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * auto /
		 * 	<= lobby_update
		 * 	<= redirects
		 * ```
		 */
		auto() {
			return [
				this.lobby_update(),
				this.redirects()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_title \$hyoo_match
		 * ```
		 */
		menu_title() {
			return "$hyoo_match"
		}
		
		/**
		 * ```tree
		 * menu_tools / <= Lights
		 * ```
		 */
		menu_tools() {
			return [
				this.Lights()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_body /
		 * 	<= Menu_links
		 * 	<= Stats
		 * ```
		 */
		menu_body() {
			return [
				this.Menu_links(),
				this.Stats()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_foot /
		 * 	<= Online
		 * 	<= Source
		 * 	<= Support
		 * ```
		 */
		menu_foot() {
			return [
				this.Online(),
				this.Source(),
				this.Support()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * Final $hyoo_match_final self <= self
		 * ```
		 */
		@ $mol_mem
		Final() {
			const obj = new this.$.$hyoo_match_final()
			
			obj.self = () => this.self()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Pair $hyoo_match_single_page pair <= pair
		 * ```
		 */
		@ $mol_mem
		Pair() {
			const obj = new this.$.$hyoo_match_single_page()
			
			obj.pair = () => this.pair()
			
			return obj
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	\
		 * 		<= Intro
		 * 	settings <= Settings
		 * 	look <= Look
		 * 	safe <= Safe
		 * 	lobby <= Lobby
		 * ```
		 */
		spreads() {
			return {
				"": this.Intro(),
				settings: this.Settings(),
				look: this.Look(),
				safe: this.Safe(),
				lobby: this.Lobby()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * lobby_update null
		 * ```
		 */
		lobby_update() {
			return null as any
		}
		
		/**
		 * ```tree
		 * redirects null
		 * ```
		 */
		redirects() {
			return null as any
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * stats \
		 * ```
		 */
		stats() {
			return ""
		}
		
		/**
		 * ```tree
		 * Stats $mol_paragraph title <= stats
		 * ```
		 */
		@ $mol_mem
		Stats() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.stats()
			
			return obj
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_client
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_client()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Online $hyoo_sync_online yard <= yard
		 * ```
		 */
		@ $mol_mem
		Online() {
			const obj = new this.$.$hyoo_sync_online()
			
			obj.yard = () => this.yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Source $mol_link_source uri \https://github.com/hyoo-ru/match.hyoo.ru/
		 * ```
		 */
		@ $mol_mem
		Source() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/match.hyoo.ru/"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Support_icon $mol_icon_face_agent
		 * ```
		 */
		@ $mol_mem
		Support_icon() {
			const obj = new this.$.$mol_icon_face_agent()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Support $mol_link_iconed
		 * 	hint \Поддержка
		 * 	uri \https://t.me/h_y_o_o/417
		 * 	sub / <= Support_icon
		 * ```
		 */
		@ $mol_mem
		Support() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.hint = () => "Поддержка"
			obj.uri = () => "https://t.me/h_y_o_o/417"
			obj.sub = () => [
				this.Support_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Intro $hyoo_match_intro
		 * ```
		 */
		@ $mol_mem
		Intro() {
			const obj = new this.$.$hyoo_match_intro()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Settings $hyoo_match_single_settings single <= self
		 * ```
		 */
		@ $mol_mem
		Settings() {
			const obj = new this.$.$hyoo_match_single_settings()
			
			obj.single = () => this.self()
			
			return obj
		}
		
		/**
		 * ```tree
		 * look_pages /
		 * ```
		 */
		look_pages() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Look $mol_book2 pages <= look_pages
		 * ```
		 */
		@ $mol_mem
		Look() {
			const obj = new this.$.$mol_book2()
			
			obj.pages = () => this.look_pages()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Safe $hyoo_meta_safe yard <= yard
		 * ```
		 */
		@ $mol_mem
		Safe() {
			const obj = new this.$.$hyoo_meta_safe()
			
			obj.yard = () => this.yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lobby $hyoo_match_lobby_page lobby <= lobby
		 * ```
		 */
		@ $mol_mem
		Lobby() {
			const obj = new this.$.$hyoo_match_lobby_page()
			
			obj.lobby = () => this.lobby()
			
			return obj
		}
	}
	
}

