namespace $ {
	export class $hyoo_match_places extends $mol_select_list {
		
		/**
		 * ```tree
		 * dictionary *
		 * 	mars \Марс
		 * 	moon \Луна
		 * 	earth \Земля
		 * 	RU-MOW \Москва
		 * 	RU-SPE \Санкт-Петербург
		 * 	UA-40 \Севастополь
		 * 	RU-AMU \Амурская область
		 * 	RU-ARK \Архангельская область
		 * 	RU-AST \Астраханская область
		 * 	RU-BEL \Белгородская область
		 * 	RU-BRY \Брянская область
		 * 	RU-VLA \Владимирская область
		 * 	RU-VGG \Волгоградская область
		 * 	RU-VLG \Вологодская область
		 * 	RU-VOR \Воронежская область
		 * 	RU-IVA \Ивановская область
		 * 	RU-IRK \Иркутская область
		 * 	RU-KGD \Калининградская область
		 * 	RU-KLU \Калужская область
		 * 	RU-KEM \Кемеровская область
		 * 	RU-KIR \Кировская область
		 * 	RU-KOS \Костромская область
		 * 	RU-KGN \Курганская область
		 * 	RU-KRS \Курская область
		 * 	RU-LEN \Ленинградская область
		 * 	RU-LIP \Липецкая область
		 * 	RU-MAG \Магаданская область
		 * 	RU-MOS \Московская область
		 * 	RU-MUR \Мурманская область
		 * 	RU-NIZ \Нижегородская область
		 * 	RU-NGR \Новгородская область
		 * 	RU-NVS \Новосибирская область
		 * 	RU-OMS \Омская область
		 * 	RU-ORE \Оренбургская область
		 * 	RU-ORL \Орловская область
		 * 	RU-PNZ \Пензенская область
		 * 	RU-PSK \Псковская область
		 * 	RU-ROS \Ростовская область
		 * 	RU-RYA \Рязанская область
		 * 	RU-SAM \Самарская область
		 * 	RU-SAR \Саратовская область
		 * 	RU-SAK \Сахалинская область
		 * 	RU-SVE \Свердловская область
		 * 	RU-SMO \Смоленская область
		 * 	RU-TAM \Тамбовская область
		 * 	RU-TVE \Тверская область
		 * 	RU-TOM \Томская область
		 * 	RU-TUL \Тульская область
		 * 	RU-TYU \Тюменская область
		 * 	RU-ULY \Ульяновская область
		 * 	RU-CHE \Челябинская область
		 * 	RU-YAR \Ярославская область
		 * 	RU-AD \Адыгея 
		 * 	RU-BA \Башкортостан
		 * 	RU-BU \Бурятия
		 * 	RU-DA \Дагестан
		 * 	RU-IN \Ингушетия
		 * 	RU-KB \Кабардино-Балкария
		 * 	RU-KL \Калмыкия
		 * 	RU-KC \Карачаево-Черкесия
		 * 	RU-KR \Карелия
		 * 	RU-ME \Марий Эл
		 * 	RU-MO \Мордовия
		 * 	RU-AL \Республика Алтай
		 * 	RU-KO \Республика Коми
		 * 	RU-SA \Якутия
		 * 	RU-SE \Северная Осетия
		 * 	RU-TA \Татарстан
		 * 	RU-TY \Тыва
		 * 	RU-UD \Удмуртия
		 * 	RU-KK \Хакасия
		 * 	RU-CE \Чечня
		 * 	RU-CU \Чувашия
		 * 	RU-ALT \Алтайский край 
		 * 	RU-ZAB \Забайкальский край
		 * 	RU-KAM \Камчатский край
		 * 	RU-KDA \Краснодарский край
		 * 	RU-KYA \Красноярский край
		 * 	RU-PER \Пермский край
		 * 	RU-PRI \Приморский край
		 * 	RU-STA \Ставропольский край
		 * 	RU-KHA \Хабаровский край
		 * 	RU-NEN \Ненецкий АО
		 * 	RU-KHM \Ханты-Мансийский АО
		 * 	RU-CHU \Чукотский АО
		 * 	RU-YAN \Ямало-Ненецкий АО
		 * 	RU-YEV \Еврейская АО
		 * 	UA-43 \Крым
		 * 	RU \Россия
		 * 	By \Беларусь
		 * 	UA \Украина
		 * 	KG \Киргизия
		 * 	KZ \Казахстан
		 * 	MD \Молдавия
		 * 	RO \Румыния
		 * 	TJ \Таджикистан
		 * 	UZ \Узбекистан
		 * 	IL \Израиль
		 * 	US \США
		 * 	AU \Австралия
		 * 	AM \Армения
		 * 	CN \Китай
		 * 	PL \Польша
		 * 	SK \Словакия
		 * 	FI \Финляндия
		 * 	HR \Хорватия
		 * 	CZ \Чехия
		 * 	LV \Латвия
		 * 	GE \Грузия
		 * 	europa \Европа
		 * 	asia \Азия
		 * 	africa \Африка
		 * 	aerica-south \Южная Америка
		 * 	america-nord \Северная Америка
		 * 	antarctica \Антарктида
		 * 	oceania \Oceania
		 * ```
		 */
		dictionary() {
			return {
				mars: "Марс",
				moon: "Луна",
				earth: "Земля",
				"RU-MOW": "Москва",
				"RU-SPE": "Санкт-Петербург",
				"UA-40": "Севастополь",
				"RU-AMU": "Амурская область",
				"RU-ARK": "Архангельская область",
				"RU-AST": "Астраханская область",
				"RU-BEL": "Белгородская область",
				"RU-BRY": "Брянская область",
				"RU-VLA": "Владимирская область",
				"RU-VGG": "Волгоградская область",
				"RU-VLG": "Вологодская область",
				"RU-VOR": "Воронежская область",
				"RU-IVA": "Ивановская область",
				"RU-IRK": "Иркутская область",
				"RU-KGD": "Калининградская область",
				"RU-KLU": "Калужская область",
				"RU-KEM": "Кемеровская область",
				"RU-KIR": "Кировская область",
				"RU-KOS": "Костромская область",
				"RU-KGN": "Курганская область",
				"RU-KRS": "Курская область",
				"RU-LEN": "Ленинградская область",
				"RU-LIP": "Липецкая область",
				"RU-MAG": "Магаданская область",
				"RU-MOS": "Московская область",
				"RU-MUR": "Мурманская область",
				"RU-NIZ": "Нижегородская область",
				"RU-NGR": "Новгородская область",
				"RU-NVS": "Новосибирская область",
				"RU-OMS": "Омская область",
				"RU-ORE": "Оренбургская область",
				"RU-ORL": "Орловская область",
				"RU-PNZ": "Пензенская область",
				"RU-PSK": "Псковская область",
				"RU-ROS": "Ростовская область",
				"RU-RYA": "Рязанская область",
				"RU-SAM": "Самарская область",
				"RU-SAR": "Саратовская область",
				"RU-SAK": "Сахалинская область",
				"RU-SVE": "Свердловская область",
				"RU-SMO": "Смоленская область",
				"RU-TAM": "Тамбовская область",
				"RU-TVE": "Тверская область",
				"RU-TOM": "Томская область",
				"RU-TUL": "Тульская область",
				"RU-TYU": "Тюменская область",
				"RU-ULY": "Ульяновская область",
				"RU-CHE": "Челябинская область",
				"RU-YAR": "Ярославская область",
				"RU-AD": "Адыгея ",
				"RU-BA": "Башкортостан",
				"RU-BU": "Бурятия",
				"RU-DA": "Дагестан",
				"RU-IN": "Ингушетия",
				"RU-KB": "Кабардино-Балкария",
				"RU-KL": "Калмыкия",
				"RU-KC": "Карачаево-Черкесия",
				"RU-KR": "Карелия",
				"RU-ME": "Марий Эл",
				"RU-MO": "Мордовия",
				"RU-AL": "Республика Алтай",
				"RU-KO": "Республика Коми",
				"RU-SA": "Якутия",
				"RU-SE": "Северная Осетия",
				"RU-TA": "Татарстан",
				"RU-TY": "Тыва",
				"RU-UD": "Удмуртия",
				"RU-KK": "Хакасия",
				"RU-CE": "Чечня",
				"RU-CU": "Чувашия",
				"RU-ALT": "Алтайский край ",
				"RU-ZAB": "Забайкальский край",
				"RU-KAM": "Камчатский край",
				"RU-KDA": "Краснодарский край",
				"RU-KYA": "Красноярский край",
				"RU-PER": "Пермский край",
				"RU-PRI": "Приморский край",
				"RU-STA": "Ставропольский край",
				"RU-KHA": "Хабаровский край",
				"RU-NEN": "Ненецкий АО",
				"RU-KHM": "Ханты-Мансийский АО",
				"RU-CHU": "Чукотский АО",
				"RU-YAN": "Ямало-Ненецкий АО",
				"RU-YEV": "Еврейская АО",
				"UA-43": "Крым",
				RU: "Россия",
				By: "Беларусь",
				UA: "Украина",
				KG: "Киргизия",
				KZ: "Казахстан",
				MD: "Молдавия",
				RO: "Румыния",
				TJ: "Таджикистан",
				UZ: "Узбекистан",
				IL: "Израиль",
				US: "США",
				AU: "Австралия",
				AM: "Армения",
				CN: "Китай",
				PL: "Польша",
				SK: "Словакия",
				FI: "Финляндия",
				HR: "Хорватия",
				CZ: "Чехия",
				LV: "Латвия",
				GE: "Грузия",
				europa: "Европа",
				asia: "Азия",
				africa: "Африка",
				"aerica-south": "Южная Америка",
				"america-nord": "Северная Америка",
				antarctica: "Антарктида",
				oceania: "Oceania"
			} as Record< string, any >
		}
	}
	
}

