namespace $ {
	export class $hyoo_match_single_card extends $mol_view {
		
		/**
		 * ```tree
		 * name
		 * ```
		 */
		name() {
			return this.single().title()
		}
		
		/**
		 * ```tree
		 * greet
		 * ```
		 */
		greet() {
			return this.single().greet()
		}
		
		/**
		 * ```tree
		 * contacts
		 * ```
		 */
		contacts() {
			return this.single().contacts()
		}
		
		/**
		 * ```tree
		 * places
		 * ```
		 */
		places() {
			return this.single().places()
		}
		
		/**
		 * ```tree
		 * sex_self
		 * ```
		 */
		sex_self() {
			return this.single().sex_self()
		}
		
		/**
		 * ```tree
		 * age_self
		 * ```
		 */
		age_self() {
			return this.single().age_self()
		}
		
		/**
		 * ```tree
		 * sex_pref
		 * ```
		 */
		sex_pref() {
			return this.single().sex_pref_all()
		}
		
		/**
		 * ```tree
		 * age_pref
		 * ```
		 */
		age_pref() {
			return this.single().age_pref_all()
		}
		
		/**
		 * ```tree
		 * single $hyoo_match_single
		 * 	title => name
		 * 	greet => greet
		 * 	contacts => contacts
		 * 	places => places
		 * 	sex_self => sex_self
		 * 	age_self => age_self
		 * 	sex_pref_all => sex_pref
		 * 	age_pref_all => age_pref
		 * ```
		 */
		@ $mol_mem
		single() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * minimal_width 120
		 * ```
		 */
		minimal_width() {
			return 120
		}
		
		/**
		 * ```tree
		 * minimal_height 120
		 * ```
		 */
		minimal_height() {
			return 120
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Photo
		 * 	<= Info
		 * ```
		 */
		sub() {
			return [
				this.Photo(),
				this.Info()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * photo \
		 * ```
		 */
		photo() {
			return ""
		}
		
		/**
		 * ```tree
		 * Photo $mol_image uri <= photo
		 * ```
		 */
		@ $mol_mem
		Photo() {
			const obj = new this.$.$mol_image()
			
			obj.uri = () => this.photo()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_paragraph title <= name
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * tags \
		 * ```
		 */
		tags() {
			return ""
		}
		
		/**
		 * ```tree
		 * Tags $mol_paragraph title <= tags
		 * ```
		 */
		@ $mol_mem
		Tags() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => this.tags()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Places $hyoo_match_places
		 * 	enabled false
		 * 	Pick null
		 * 	value? <= places
		 * ```
		 */
		@ $mol_mem
		Places() {
			const obj = new this.$.$hyoo_match_places()
			
			obj.enabled = () => false
			obj.Pick = () => null as any
			obj.value = (next?: any) => this.places()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Filters $mol_view sub /
		 * 	<= Name
		 * 	<= Tags
		 * 	<= Places
		 * ```
		 */
		@ $mol_mem
		Filters() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Name(),
				this.Tags(),
				this.Places()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Brief $mol_text text <= greet
		 * ```
		 */
		@ $mol_mem
		Brief() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.greet()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts $mol_text text <= contacts
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.contacts()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Info $mol_list rows /
		 * 	<= Filters
		 * 	<= Brief
		 * 	<= Contacts
		 * ```
		 */
		@ $mol_mem
		Info() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Filters(),
				this.Brief(),
				this.Contacts()
			] as readonly any[]
			
			return obj
		}
	}
	
}

