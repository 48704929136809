namespace $ {
	export class $hyoo_meta_menu extends $mol_page {
		
		/**
		 * ```tree
		 * editable true
		 * ```
		 */
		editable() {
			return true
		}
		
		/**
		 * ```tree
		 * item_moved*? null
		 * ```
		 */
		@ $mol_mem_key
		item_moved(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * yard $hyoo_sync_yard
		 * ```
		 */
		@ $mol_mem
		yard() {
			const obj = new this.$.$hyoo_sync_yard()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_title*
		 * ```
		 */
		item_title(id: any) {
			return this.item(id).title()
		}
		
		/**
		 * ```tree
		 * item* $hyoo_meta_model title => item_title*
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_meta_model()
			
			return obj
		}
		
		/**
		 * ```tree
		 * ids
		 * ```
		 */
		ids() {
			return this.list().list()
		}
		
		/**
		 * ```tree
		 * world
		 * ```
		 */
		world() {
			return this.list().world()
		}
		
		/**
		 * ```tree
		 * list $hyoo_crowd_list
		 * 	list => ids
		 * 	world => world
		 * ```
		 */
		@ $mol_mem
		list() {
			const obj = new this.$.$hyoo_crowd_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_list* $hyoo_crowd_list
		 * ```
		 */
		@ $mol_mem_key
		item_list(id: any) {
			const obj = new this.$.$hyoo_crowd_list()
			
			return obj
		}
		
		/**
		 * ```tree
		 * drop_allow /
		 * 	\move
		 * 	\link
		 * ```
		 */
		drop_allow() {
			return [
				"move",
				"link"
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Logo $mol_avatar id <= id
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$mol_avatar()
			
			obj.id = () => this.id()
			
			return obj
		}
		
		/**
		 * ```tree
		 * tools_ext /
		 * ```
		 */
		tools_ext() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * head /
		 * 	<= Title
		 * 	<= Tools
		 * 	<= Search
		 * ```
		 */
		head() {
			return [
				this.Title(),
				this.Tools(),
				this.Search()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Search_toggle
		 * 	<= Editing
		 * 	<= Add
		 * 	^ tools_ext
		 * ```
		 */
		tools() {
			return [
				this.Search_toggle(),
				this.Editing(),
				this.Add(),
				...this.tools_ext()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Found
		 * 	<= Content
		 * 	<= Drop_end
		 * ```
		 */
		body() {
			return [
				this.Found(),
				this.Content(),
				this.Drop_end()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * id \
		 * ```
		 */
		id() {
			return ""
		}
		
		/**
		 * ```tree
		 * search? \
		 * ```
		 */
		@ $mol_mem
		search(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Search $mol_search query? <=> search?
		 * ```
		 */
		@ $mol_mem
		Search() {
			const obj = new this.$.$mol_search()
			
			obj.query = (next?: any) => this.search(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * search_show? false
		 * ```
		 */
		@ $mol_mem
		search_show(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Search_icon $mol_icon_magnify
		 * ```
		 */
		@ $mol_mem
		Search_icon() {
			const obj = new this.$.$mol_icon_magnify()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Search_toggle $mol_check_icon
		 * 	hint @ \Fulltext search
		 * 	checked? <=> search_show?
		 * 	Icon <= Search_icon
		 * ```
		 */
		@ $mol_mem
		Search_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_Search_toggle_hint' )
			obj.checked = (next?: any) => this.search_show(next)
			obj.Icon = () => this.Search_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * editing? false
		 * ```
		 */
		@ $mol_mem
		editing(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Editing_icon $mol_icon_pencil_outline
		 * ```
		 */
		@ $mol_mem
		Editing_icon() {
			const obj = new this.$.$mol_icon_pencil_outline()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Editing $mol_check_icon
		 * 	hint @ \Edit list
		 * 	checked? <=> editing?
		 * 	Icon <= Editing_icon
		 * ```
		 */
		@ $mol_mem
		Editing() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_Editing_hint' )
			obj.checked = (next?: any) => this.editing(next)
			obj.Icon = () => this.Editing_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * add? null
		 * ```
		 */
		@ $mol_mem
		add(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Add_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Add_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Add $mol_button_minor
		 * 	hint @ \Add new
		 * 	click? <=> add?
		 * 	sub / <= Add_icon
		 * ```
		 */
		@ $mol_mem
		Add() {
			const obj = new this.$.$mol_button_minor()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_meta_menu_Add_hint' )
			obj.click = (next?: any) => this.add(next)
			obj.sub = () => [
				this.Add_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * found /$mol_int62_string
		 * ```
		 */
		found() {
			return [
			] as readonly $mol_int62_string[]
		}
		
		/**
		 * ```tree
		 * item_uri* \
		 * ```
		 */
		item_uri(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * item_add*? null
		 * ```
		 */
		@ $mol_mem_key
		item_add(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Found $hyoo_meta_menu_items
		 * 	editing <= editing
		 * 	drop_allow /
		 * 	list <= list
		 * 	ids <= found
		 * 	highlight <= search
		 * 	item_uri* <= item_uri*
		 * 	item_moved*? <=> item_moved*?
		 * 	item_add*? <=> item_add*?
		 * ```
		 */
		@ $mol_mem
		Found() {
			const obj = new this.$.$hyoo_meta_menu_items()
			
			obj.editing = () => this.editing()
			obj.drop_allow = () => [
			] as readonly any[]
			obj.list = () => this.list()
			obj.ids = () => this.found()
			obj.highlight = () => this.search()
			obj.item_uri = (id: any) => this.item_uri(id)
			obj.item_moved = (id: any, next?: any) => this.item_moved(id, next)
			obj.item_add = (id: any, next?: any) => this.item_add(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_expanded*? false
		 * ```
		 */
		@ $mol_mem_key
		item_expanded(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Content $hyoo_meta_menu_items
		 * 	editing <= editing
		 * 	drop_allow <= drop_allow
		 * 	list <= list
		 * 	item_uri* <= item_uri*
		 * 	item_expanded*? <=> item_expanded*?
		 * 	item_moved*? <=> item_moved*?
		 * 	item_list* <= item_list*
		 * 	item_add*? <=> item_add*?
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$hyoo_meta_menu_items()
			
			obj.editing = () => this.editing()
			obj.drop_allow = () => this.drop_allow()
			obj.list = () => this.list()
			obj.item_uri = (id: any) => this.item_uri(id)
			obj.item_expanded = (id: any, next?: any) => this.item_expanded(id, next)
			obj.item_moved = (id: any, next?: any) => this.item_moved(id, next)
			obj.item_list = (id: any) => this.item_list(id)
			obj.item_add = (id: any, next?: any) => this.item_add(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * transfer_adopt? null
		 * ```
		 */
		@ $mol_mem
		transfer_adopt(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * receive_end? null
		 * ```
		 */
		@ $mol_mem
		receive_end(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Drop_zone $mol_view
		 * ```
		 */
		@ $mol_mem
		Drop_zone() {
			const obj = new this.$.$mol_view()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Drop_end $mol_drop
		 * 	enabled <= editable
		 * 	adopt? <=> transfer_adopt?
		 * 	receive? <=> receive_end?
		 * 	Sub <= Drop_zone
		 * 	allow <= drop_allow
		 * ```
		 */
		@ $mol_mem
		Drop_end() {
			const obj = new this.$.$mol_drop()
			
			obj.enabled = () => this.editable()
			obj.adopt = (next?: any) => this.transfer_adopt(next)
			obj.receive = (next?: any) => this.receive_end(next)
			obj.Sub = () => this.Drop_zone()
			obj.allow = () => this.drop_allow()
			
			return obj
		}
	}
	
}

