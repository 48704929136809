$hyoo_match_final $mol_page
	title \Ждём персон
	self $hyoo_match_single
	foot /
		<= Reset $mol_button_minor
			title \Заново 💦
			click? <=> reset? null
	body /
		<= Result $mol_text text \Никого пока нет под твои критерии 😭
		<= Share $mol_button_share
			capture <= Content
			uri \/
			title \Расскажи остальным:
		<= Content $mol_text
			text \
				\" 💞 Поздравляем всех, кто уже нашёл свою вторую половинку!
				\" 💔 А кто сейчас грустит один - заползай на match.hyoo.ru - сервис знакомств от студента ИТМО, у которого сердце за тебя болит.
				\" ✨ Никаких фейков, мёртвых душ, рекламы, премиумов и геймификаций. Ищи, знакомься, общайся. Пока не найдёшь, наконец, свою судьбу.
				\" 🤙 Поделись в своих каналах и закидывай в чаты, где обитаешь, и где наверняка есть такие же страждущие.
				\" 🙏 Проект не коммерческий, так что вся надежда на тебя: чем больше соберём одиночек, тем меньше станет одиночек.
