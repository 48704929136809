namespace $ {
	export class $mol_icon_share extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M21,12L14,5V9C7,10 4,15 3,20C5.5,16.5 9,14.9 14,14.9V19L21,12Z
		 * ```
		 */
		path() {
			return "M21,12L14,5V9C7,10 4,15 3,20C5.5,16.5 9,14.9 14,14.9V19L21,12Z"
		}
	}
	
}

