namespace $ {
	export class $mol_icon_shield extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z
		 * ```
		 */
		path() {
			return "M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z"
		}
	}
	
}

