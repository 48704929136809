namespace $ {
	export class $hyoo_page_side_news extends $mol_page {
		
		/**
		 * ```tree
		 * news
		 * ```
		 */
		news() {
			return this.side().news()
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side news => news
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title @ \Interesting
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_page_side_news_title' )
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	^
		 * 	mol_theme \$mol_theme_special
		 * ```
		 */
		attr() {
			return {
				...super.attr(),
				mol_theme: "$mol_theme_special"
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * body / <= Section_list
		 * ```
		 */
		body() {
			return [
				this.Section_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * item* $hyoo_page_side
		 * ```
		 */
		@ $mol_mem_key
		item(id: any) {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * item_title* \
		 * ```
		 */
		item_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Item*0 $hyoo_meta_link
		 * 	meta <= item*
		 * 	title <= item_title*
		 * 	Avatar null
		 * ```
		 */
		@ $mol_mem_key
		Item(id: any) {
			const obj = new this.$.$hyoo_meta_link()
			
			obj.meta = () => this.item(id)
			obj.title = () => this.item_title(id)
			obj.Avatar = () => null as any
			
			return obj
		}
		
		/**
		 * ```tree
		 * items / <= Item*0
		 * ```
		 */
		items() {
			return [
				this.Item("0")
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Section_list $mol_list rows <= items
		 * ```
		 */
		@ $mol_mem
		Section_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.items()
			
			return obj
		}
	}
	
}

