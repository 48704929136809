$hyoo_match_univer $mol_select
	options /
		\Академия тыла
		\Архи Строй
		\Бонч-Бруевича
		\Вагановка
		\Ветеринарный
		\Воен Мед
		\Воен Мор
		\Военмех
		\ГИКиТ
		\ГУАП
		\Герцена
		\Гидромет
		\Горный
		\Гос Пед Мед
		\Граж авиа
		\Духовная академия
		\ИТМО
		\Консерватория
		\Корабелка
		\Кулёк
		\ЛЭТИ
		\Лесгафта
		\Лесопилка
		\МБИ
		\МВД
		\МЧС
		\Макаровка
		\Мечникова
		\Михайловка
		\Можайка
		\Муха
		\Пед Мед
		\Первый мед
		\Политех
		\Профсоюзов
		\Путей сообщения
		\РХГА
		\Репина
		\СПбГУ
		\Связи
		\Театралка
		\Текстильный
		\Техноложка
		\Финэк
		\Фрейда
		\Хим-Фарма
		\Христиански
