namespace $ {
	export class $hyoo_page_side_edit extends $mol_page {
		
		/**
		 * ```tree
		 * title?
		 * ```
		 */
		title(next?: any) {
			return this.side().title(next)
		}
		
		/**
		 * ```tree
		 * details?
		 * ```
		 */
		details(next?: any) {
			return this.side().details(next)
		}
		
		/**
		 * ```tree
		 * details_selection?
		 * ```
		 */
		details_selection(next?: any) {
			return this.side().details_selection(next)
		}
		
		/**
		 * ```tree
		 * aura?
		 * ```
		 */
		aura(next?: any) {
			return this.side().aura(next)
		}
		
		/**
		 * ```tree
		 * editable
		 * ```
		 */
		editable() {
			return this.side().editable()
		}
		
		/**
		 * ```tree
		 * released
		 * ```
		 */
		released() {
			return this.side().released()
		}
		
		/**
		 * ```tree
		 * side $hyoo_page_side
		 * 	title? => title?
		 * 	details? => details?
		 * 	details_selection? => details_selection?
		 * 	aura? => aura?
		 * 	editable => editable
		 * 	released => released
		 * ```
		 */
		@ $mol_mem
		side() {
			const obj = new this.$.$hyoo_page_side()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title $mol_string_button
		 * 	hint @ \Title
		 * 	value? <=> title?
		 * 	enabled <= editable
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_string_button()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Title_hint' )
			obj.value = (next?: any) => this.title(next)
			obj.enabled = () => this.editable()
			
			return obj
		}
		
		/**
		 * ```tree
		 * export_sign @ \\\Current Original on $hyoo_page.\{link}\\
		 * ```
		 */
		export_sign() {
			return this.$.$mol_locale.text( '$hyoo_page_side_edit_export_sign' )
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Syntax
		 * 	<= Publish
		 * 	<= Export
		 * 	<= Rights_toggle
		 * 	<= Close
		 * ```
		 */
		tools() {
			return [
				this.Syntax(),
				this.Publish(),
				this.Export(),
				this.Rights_toggle(),
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Details_edit
		 * 	<= Aura_field
		 * ```
		 */
		body() {
			return [
				this.Details_edit(),
				this.Aura_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Syntax $mol_link_iconed
		 * 	hint @ \MarkedText Syntax
		 * 	uri \https://marked.hyoo.ru/
		 * 	icon \https://marked.hyoo.ru/hyoo/marked/logo/logo.svg
		 * 	title \
		 * ```
		 */
		@ $mol_mem
		Syntax() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Syntax_hint' )
			obj.uri = () => "https://marked.hyoo.ru/"
			obj.icon = () => "https://marked.hyoo.ru/hyoo/marked/logo/logo.svg"
			obj.title = () => ""
			
			return obj
		}
		
		/**
		 * ```tree
		 * publish? null
		 * ```
		 */
		@ $mol_mem
		publish(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Publish_icon $mol_icon_publish
		 * ```
		 */
		@ $mol_mem
		Publish_icon() {
			const obj = new this.$.$mol_icon_publish()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Publish $mol_button_minor
		 * 	click? <=> publish?
		 * 	disabled <= released
		 * 	hint @ \Publish
		 * 	sub / <= Publish_icon
		 * ```
		 */
		@ $mol_mem
		Publish() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.publish(next)
			obj.disabled = () => this.released()
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Publish_hint' )
			obj.sub = () => [
				this.Publish_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Export_icon $mol_icon_export
		 * ```
		 */
		@ $mol_mem
		Export_icon() {
			const obj = new this.$.$mol_icon_export()
			
			return obj
		}
		
		/**
		 * ```tree
		 * download_name \{filename}.mt
		 * ```
		 */
		download_name() {
			return "{filename}.mt"
		}
		
		/**
		 * ```tree
		 * download_blob $mol_blob
		 * ```
		 */
		@ $mol_mem
		download_blob() {
			const obj = new this.$.$mol_blob()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Download $mol_button_download
		 * 	title @ \Download Book as MT File
		 * 	file_name <= download_name
		 * 	blob <= download_blob
		 * ```
		 */
		@ $mol_mem
		Download() {
			const obj = new this.$.$mol_button_download()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Download_title' )
			obj.file_name = () => this.download_name()
			obj.blob = () => this.download_blob()
			
			return obj
		}
		
		/**
		 * ```tree
		 * copy_text \
		 * ```
		 */
		copy_text() {
			return ""
		}
		
		/**
		 * ```tree
		 * copy_html \
		 * ```
		 */
		copy_html() {
			return ""
		}
		
		/**
		 * ```tree
		 * Copy_html $mol_button_copy
		 * 	title @ \Copy to Clipboard
		 * 	text <= copy_text
		 * 	html <= copy_html
		 * ```
		 */
		@ $mol_mem
		Copy_html() {
			const obj = new this.$.$mol_button_copy()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Copy_html_title' )
			obj.text = () => this.copy_text()
			obj.html = () => this.copy_html()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Export $mol_pick
		 * 	hint @ \Download markdown
		 * 	trigger_content / <= Export_icon
		 * 	bubble_content /
		 * 		<= Download
		 * 		<= Copy_html
		 * ```
		 */
		@ $mol_mem
		Export() {
			const obj = new this.$.$mol_pick()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Export_hint' )
			obj.trigger_content = () => [
				this.Export_icon()
			] as readonly any[]
			obj.bubble_content = () => [
				this.Download(),
				this.Copy_html()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * rights? false
		 * ```
		 */
		@ $mol_mem
		rights(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Rights_icon $mol_icon_shield_account
		 * ```
		 */
		@ $mol_mem
		Rights_icon() {
			const obj = new this.$.$mol_icon_shield_account()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Rights_toggle $mol_check_icon
		 * 	hint @ \Access Rights
		 * 	checked? <=> rights?
		 * 	Icon <= Rights_icon
		 * ```
		 */
		@ $mol_mem
		Rights_toggle() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Rights_toggle_hint' )
			obj.checked = (next?: any) => this.rights(next)
			obj.Icon = () => this.Rights_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * close? null
		 * ```
		 */
		@ $mol_mem
		close(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_button_minor
		 * 	click? <=> close?
		 * 	sub / <= Close_icon
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.close(next)
			obj.sub = () => [
				this.Close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Details_edit $mol_textarea
		 * 	hint @ \Details
		 * 	value? <=> details?
		 * 	selection? <=> details_selection?
		 * 	enabled <= editable
		 * ```
		 */
		@ $mol_mem
		Details_edit() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Details_edit_hint' )
			obj.value = (next?: any) => this.details(next)
			obj.selection = (next?: any) => this.details_selection(next)
			obj.enabled = () => this.editable()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Aura $mol_string_button
		 * 	hint @ \hyper link
		 * 	value? <=> aura?
		 * 	enabled <= editable
		 * ```
		 */
		@ $mol_mem
		Aura() {
			const obj = new this.$.$mol_string_button()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Aura_hint' )
			obj.value = (next?: any) => this.aura(next)
			obj.enabled = () => this.editable()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Aura_field $mol_form_field
		 * 	name @ \Page Background
		 * 	Content <= Aura
		 * ```
		 */
		@ $mol_mem
		Aura_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_page_side_edit_Aura_field_name' )
			obj.Content = () => this.Aura()
			
			return obj
		}
	}
	
}

