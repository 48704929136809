namespace $ {
	export class $mol_icon_pin extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z
		 * ```
		 */
		path() {
			return "M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"
		}
	}
	
}

