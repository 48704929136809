namespace $ {
	export class $hyoo_match_single_settings extends $mol_page {
		
		/**
		 * ```tree
		 * title \Профиль
		 * ```
		 */
		title() {
			return "Профиль"
		}
		
		/**
		 * ```tree
		 * ready
		 * ```
		 */
		ready() {
			return this.single().ready()
		}
		
		/**
		 * ```tree
		 * photo_fresh
		 * ```
		 */
		photo_fresh() {
			return this.single().photo_fresh()
		}
		
		/**
		 * ```tree
		 * name?
		 * ```
		 */
		name(next?: any) {
			return this.single().title(next)
		}
		
		/**
		 * ```tree
		 * greet?
		 * ```
		 */
		greet(next?: any) {
			return this.single().greet(next)
		}
		
		/**
		 * ```tree
		 * contacts?
		 * ```
		 */
		contacts(next?: any) {
			return this.single().contacts(next)
		}
		
		/**
		 * ```tree
		 * places?
		 * ```
		 */
		places(next?: any) {
			return this.single().places(next)
		}
		
		/**
		 * ```tree
		 * sex_self?
		 * ```
		 */
		sex_self(next?: any) {
			return this.single().sex_self(next)
		}
		
		/**
		 * ```tree
		 * age_self?
		 * ```
		 */
		age_self(next?: any) {
			return this.single().age_self(next)
		}
		
		/**
		 * ```tree
		 * sex_pref*?
		 * ```
		 */
		sex_pref(id: any, next?: any) {
			return this.single().sex_pref(id, next)
		}
		
		/**
		 * ```tree
		 * sex_pref_all
		 * ```
		 */
		sex_pref_all() {
			return this.single().sex_pref_all()
		}
		
		/**
		 * ```tree
		 * age_pref*?
		 * ```
		 */
		age_pref(id: any, next?: any) {
			return this.single().age_pref(id, next)
		}
		
		/**
		 * ```tree
		 * age_pref_all
		 * ```
		 */
		age_pref_all() {
			return this.single().age_pref_all()
		}
		
		/**
		 * ```tree
		 * single $hyoo_match_single
		 * 	ready => ready
		 * 	photo_fresh => photo_fresh
		 * 	title? => name?
		 * 	greet? => greet?
		 * 	contacts? => contacts?
		 * 	places? => places?
		 * 	sex_self? => sex_self?
		 * 	age_self? => age_self?
		 * 	sex_pref*? => sex_pref*?
		 * 	sex_pref_all => sex_pref_all
		 * 	age_pref*? => age_pref*?
		 * 	age_pref_all => age_pref_all
		 * ```
		 */
		@ $mol_mem
		single() {
			const obj = new this.$.$hyoo_match_single()
			
			return obj
		}
		
		/**
		 * ```tree
		 * bid_required* \Обязательно
		 * ```
		 */
		bid_required(id: any) {
			return "Обязательно"
		}
		
		/**
		 * ```tree
		 * bid_one* \Хотябы один
		 * ```
		 */
		bid_one(id: any) {
			return "Хотябы один"
		}
		
		/**
		 * ```tree
		 * foot /
		 * 	<= Incoplete
		 * 	<= Next
		 * ```
		 */
		foot() {
			return [
				this.Incoplete(),
				this.Next()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body /
		 * 	<= Shot_field
		 * 	<= Name_field
		 * 	<= Tags
		 * 	<= Places_field
		 * 	<= Greet_field
		 * 	<= Contacts_hint
		 * 	<= Contacts_field
		 * ```
		 */
		body() {
			return [
				this.Shot_field(),
				this.Name_field(),
				this.Tags(),
				this.Places_field(),
				this.Greet_field(),
				this.Contacts_hint(),
				this.Contacts_field()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Incoplete $mol_paragraph title \Недозаполнен 🙈
		 * ```
		 */
		@ $mol_mem
		Incoplete() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => "Недозаполнен 🙈"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Next $mol_link
		 * 	title \Виден всем ✅
		 * 	arg * \
		 * 		\look
		 * ```
		 */
		@ $mol_mem
		Next() {
			const obj = new this.$.$mol_link()
			
			obj.title = () => "Виден всем ✅"
			obj.arg = () => ({
				"": "look"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * shot_bid \Нужно свежее
		 * ```
		 */
		shot_bid() {
			return "Нужно свежее"
		}
		
		/**
		 * ```tree
		 * live? true
		 * ```
		 */
		@ $mol_mem
		live(next?: any) {
			if ( next !== undefined ) return next as never
			return true
		}
		
		/**
		 * ```tree
		 * photo \
		 * ```
		 */
		photo() {
			return ""
		}
		
		/**
		 * ```tree
		 * Photo $mol_image uri <= photo
		 * ```
		 */
		@ $mol_mem
		Photo() {
			const obj = new this.$.$mol_image()
			
			obj.uri = () => this.photo()
			
			return obj
		}
		
		/**
		 * ```tree
		 * video
		 * ```
		 */
		video() {
			return this.Camera().dom_node_actual()
		}
		
		/**
		 * ```tree
		 * Camera $mol_video_camera
		 * 	dom_node_actual => video
		 * 	aspect 1
		 * ```
		 */
		@ $mol_mem
		Camera() {
			const obj = new this.$.$mol_video_camera()
			
			obj.aspect = () => 1
			
			return obj
		}
		
		/**
		 * ```tree
		 * Shot_hint $mol_paragraph title \Жми чтобы сфотаться
		 * ```
		 */
		@ $mol_mem
		Shot_hint() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => "Жми чтобы сфотаться"
			
			return obj
		}
		
		/**
		 * ```tree
		 * shot_content /
		 * 	<= Photo
		 * 	<= Camera
		 * 	<= Shot_hint
		 * ```
		 */
		shot_content() {
			return [
				this.Photo(),
				this.Camera(),
				this.Shot_hint()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Shot $mol_check
		 * 	checked? <=> live?
		 * 	sub <= shot_content
		 * ```
		 */
		@ $mol_mem
		Shot() {
			const obj = new this.$.$mol_check()
			
			obj.checked = (next?: any) => this.live(next)
			obj.sub = () => this.shot_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Shot_field $mol_form_field
		 * 	name \Сегодняшнее фото
		 * 	bids / <= shot_bid
		 * 	Content <= Shot
		 * ```
		 */
		@ $mol_mem
		Shot_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Сегодняшнее фото"
			obj.bids = () => [
				this.shot_bid()
			] as readonly any[]
			obj.Content = () => this.Shot()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name $mol_string value? <=> name?
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.name(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name \Имя
		 * 	bids / <= bid_required*name
		 * 	Content <= Name
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Имя"
			obj.bids = () => [
				this.bid_required("name")
			] as readonly any[]
			obj.Content = () => this.Name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Age_self $mol_switch
		 * 	value? <=> age_self?
		 * 	options *
		 * 		young \Молодой
		 * 		adult \Взрослый
		 * 		mature \Зрелый
		 * ```
		 */
		@ $mol_mem
		Age_self() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (next?: any) => this.age_self(next)
			obj.options = () => ({
				young: "Молодой",
				adult: "Взрослый",
				mature: "Зрелый"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sex_self $mol_switch
		 * 	value? <=> sex_self?
		 * 	options *
		 * 		female \Женщина
		 * 		male \Мужчина
		 * ```
		 */
		@ $mol_mem
		Sex_self() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (next?: any) => this.sex_self(next)
			obj.options = () => ({
				female: "Женщина",
				male: "Мужчина"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Self_controls $mol_list rows /
		 * 	<= Age_self
		 * 	<= Sex_self
		 * ```
		 */
		@ $mol_mem
		Self_controls() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Age_self(),
				this.Sex_self()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Self $mol_form_field
		 * 	name \Я ...
		 * 	bids /
		 * 		<= bid_required*age_self
		 * 		<= bid_required*sex_self
		 * 	Content <= Self_controls
		 * ```
		 */
		@ $mol_mem
		Self() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Я ..."
			obj.bids = () => [
				this.bid_required("age_self"),
				this.bid_required("sex_self")
			] as readonly any[]
			obj.Content = () => this.Self_controls()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Age_pref $mol_check_list
		 * 	option_checked*? <=> age_pref*?
		 * 	options *
		 * 		young \Молодые
		 * 		adult \Взрослые
		 * 		mature \Зрелые
		 * ```
		 */
		@ $mol_mem
		Age_pref() {
			const obj = new this.$.$mol_check_list()
			
			obj.option_checked = (id: any, next?: any) => this.age_pref(id, next)
			obj.options = () => ({
				young: "Молодые",
				adult: "Взрослые",
				mature: "Зрелые"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sex_pref $mol_check_list
		 * 	option_checked*? <=> sex_pref*?
		 * 	options *
		 * 		female \Женщины
		 * 		male \Мужчины
		 * ```
		 */
		@ $mol_mem
		Sex_pref() {
			const obj = new this.$.$mol_check_list()
			
			obj.option_checked = (id: any, next?: any) => this.sex_pref(id, next)
			obj.options = () => ({
				female: "Женщины",
				male: "Мужчины"
			} as Record< string, any >)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Pref_controls $mol_list rows /
		 * 	<= Age_pref
		 * 	<= Sex_pref
		 * ```
		 */
		@ $mol_mem
		Pref_controls() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Age_pref(),
				this.Sex_pref()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Pref $mol_form_field
		 * 	name @ \Мне нравятся ...
		 * 	bids /
		 * 		<= bid_one*age_pref_all
		 * 		<= bid_one*sex_pref_all
		 * 	Content <= Pref_controls
		 * ```
		 */
		@ $mol_mem
		Pref() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$hyoo_match_single_settings_Pref_name' )
			obj.bids = () => [
				this.bid_one("age_pref_all"),
				this.bid_one("sex_pref_all")
			] as readonly any[]
			obj.Content = () => this.Pref_controls()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Tags $mol_form_group sub /
		 * 	<= Self
		 * 	<= Pref
		 * ```
		 */
		@ $mol_mem
		Tags() {
			const obj = new this.$.$mol_form_group()
			
			obj.sub = () => [
				this.Self(),
				this.Pref()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * places_enabled true
		 * ```
		 */
		places_enabled() {
			return true
		}
		
		/**
		 * ```tree
		 * Places $hyoo_match_places
		 * 	value? <=> places?
		 * 	pick_enabled <= places_enabled
		 * ```
		 */
		@ $mol_mem
		Places() {
			const obj = new this.$.$hyoo_match_places()
			
			obj.value = (next?: any) => this.places(next)
			obj.pick_enabled = () => this.places_enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Places_field $mol_form_field
		 * 	name \Регионы
		 * 	bids / <= bid_one*places
		 * 	Content <= Places
		 * ```
		 */
		@ $mol_mem
		Places_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Регионы"
			obj.bids = () => [
				this.bid_one("places")
			] as readonly any[]
			obj.Content = () => this.Places()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Greet $mol_textarea
		 * 	hint \заинтересуйте вашу пару
		 * 	value? <=> greet?
		 * ```
		 */
		@ $mol_mem
		Greet() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => "заинтересуйте вашу пару"
			obj.value = (next?: any) => this.greet(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Greet_field $mol_form_field
		 * 	name \Приветствие
		 * 	bids / <= bid_required*greet
		 * 	Content <= Greet
		 * ```
		 */
		@ $mol_mem
		Greet_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Приветствие"
			obj.bids = () => [
				this.bid_required("greet")
			] as readonly any[]
			obj.Content = () => this.Greet()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts_hint $mol_paragraph title \Для взаимных
		 * ```
		 */
		@ $mol_mem
		Contacts_hint() {
			const obj = new this.$.$mol_paragraph()
			
			obj.title = () => "Для взаимных"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts $mol_textarea
		 * 	hint \как с вами связаться
		 * 	value? <=> contacts?
		 * ```
		 */
		@ $mol_mem
		Contacts() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => "как с вами связаться"
			obj.value = (next?: any) => this.contacts(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contacts_field $mol_form_field
		 * 	name \Контакты
		 * 	bids / <= bid_required*contacts
		 * 	Content <= Contacts
		 * ```
		 */
		@ $mol_mem
		Contacts_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Контакты"
			obj.bids = () => [
				this.bid_required("contacts")
			] as readonly any[]
			obj.Content = () => this.Contacts()
			
			return obj
		}
	}
	
}

