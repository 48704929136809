namespace $.$$ {
	
	$mol_style_define( $hyoo_match_lobby_page, {
		
		flex: {
			basis: `40rem`,
			grow: 1,
		},
		
		Single_list: {
			gap: $mol_gap.block,
		},
		
	} )
	
}